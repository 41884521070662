import React, {Component} from "react";
import {Link} from "react-router-dom";
import MediaQuery from "react-responsive";
import {FaAngleDoubleLeft, FaAngleDoubleRight} from "react-icons/fa";

class ResourcesNav extends Component{
    scrollRight = async ()=>{
        console.log("right")
        const right = document.querySelector(".notion-breadcrumbs");
        await right.scrollBy(200, 0);
    }
    scrollLeft= async ()=>{
        const left = document.querySelector(".notion-breadcrumbs");
        await left.scrollBy(-200, 0);
    }
    componentDidMount() {
        const section = document.querySelector( '.bread-active' );
        setTimeout(()=>{
            section.scrollIntoView({ behavior: 'smooth', block: 'nearest', inline: 'center' })
        }, 1000)

    }

    render() {
        return (
            <>
                <MediaQuery minWidth={1200}>
                    <div className={"container-fluid border-bottom"}>
                        <div className={"row  px-0"} style={{height:"3rem", marginTop:"1rem"}}>
                            <div
                                className={"col-md-1 d-flex align-items-center m-0 px-0 justify-content-center"} style={{ boxShadow:"9px 2px 18px -15px rgba(0,0,0,0.75)"}}>
                                                    <span onClick={this.scrollLeft} style={{
                                                        cursor: "pointer",
                                                        border: "1px solid silver",
                                                        borderRadius: "50%",
                                                        height: "2rem",
                                                        width: "2rem",
                                                        display: "flex",
                                                        justifyContent: "center",
                                                        alignItems: "center",
                                                    }}><FaAngleDoubleLeft /></span>
                            </div>
                            <div className={"col-md-10 d-flex align-items-center"}>
                                <div  className=" notion-breadcrumbs  col-12 col-md-12 d-flex align-items-center " style={{ paddingBottom:".4rem"}} >
                                    <Link   ref={window.location.pathname==="/uxr-communities" ? (ref) => this.myRef=ref :""} to="/uxr-communities" style={{textDecoration: "none", fontSize:"16px"}}>
                                        <span className={window.location.pathname==="/uxr-communities" ? "bread-active" : ""}> Communities </span>
                                    </Link>
                                    <Link ref={window.location.pathname==="/uxr-conferences" ? (ref) => this.myRef=ref :""} to="/uxr-conferences" style={{ textDecoration: "none", fontSize:"16px"}}>
                                            <span className={window.location.pathname==="/uxr-conferences" ? "bread-active" : ""} >
                                                Conferences
                                            </span>

                                    </Link>
                                    <Link ref={window.location.pathname==="/research-tools" ? (ref) => this.myRef=ref :""} to="/research-tools" style={{textDecoration: "none", fontSize:"16px"}}>
                                                <span className={window.location.pathname==="/research-tools" ? "bread-active" : ""}  >
                                                    Tools
                                                </span>  </Link>
                                    <Link ref={window.location.pathname==="/research-vocabulary" ? (ref) => this.myRef=ref :""} to="/research-vocabulary" style={{textDecoration: "none", fontSize:"16px"}}>
                                <span className={window.location.pathname==="/research-vocabulary" ? "bread-active" : ""} >
                                Vocabulary
                                </span>
                                    </Link>
                                    <Link ref={window.location.pathname==="/volunteer-opportunities" ? (ref) => this.myRef=ref :""} to="/volunteer-opportunities" style={{textDecoration: "none", fontSize:"16px"}}>
                                <span className={window.location.pathname==="/volunteer-opportunities" ? "bread-active" : ""} >
                                Volunteer Opportunities
                                </span>
                                    </Link>
                                    <Link ref={window.location.pathname==="/ux-podcasts" ? (ref) => this.myRef=ref :""} to="/ux-podcasts" style={{textDecoration: "none", fontSize:"16px"}}>
                                <span className={window.location.pathname==="/ux-podcasts" ? "bread-active" : ""}>
                                Podcasts
                                </span>
                                    </Link>
                                    <Link ref={window.location.pathname==="/research-books" ? (ref) => this.myRef=ref :""} to="/research-books" style={{textDecoration: "none", fontSize:"16px"}}>
                                <span className={window.location.pathname==="/research-books" ? "bread-active" : ""} >
                                Books
                                </span>
                                    </Link>
                                    <Link ref={window.location.pathname==="/uxr-courses" ? (ref) => this.myRef=ref :""} to="/uxr-courses" style={{textDecoration: "none", fontSize:"16px"}}>
                                <span className={window.location.pathname==="/uxr-courses" ? "bread-active" : ""}>
                                Courses
                                </span>
                                    </Link>
                                    <Link ref={window.location.pathname==="/uxr-methods" ? (ref) => this.myRef=ref :""} to="/uxr-methods" style={{textDecoration: "none", fontSize:"16px"}}>
                                <span className={window.location.pathname==="/uxr-methods" ? "bread-active" : ""}  >
                                Methods
                                </span>
                                    </Link>
                                    <Link ref={window.location.pathname==="/rb-originals" ? (ref) => this.myRef=ref :""} to="/rb-originals" style={{textDecoration: "none", fontSize:"16px"}}>
                                <span className={window.location.pathname==="/rb-originals" ? "bread-active" : ""}  >
                               Templates
                                </span>
                                    </Link>
                                </div>
                            </div>
                            <div
                                className={"col-md-1 d-flex align-items-center m-0 px-0 justify-content-center"} style={{ boxShadow:"-9px 2px 18px -15px rgba(0,0,0,0.75)"}}>
                                                    <span onClick={this.scrollRight} style={{
                                                        cursor: "pointer",
                                                        border: "1px solid silver",
                                                        borderRadius: "50%",
                                                        height: "2rem",
                                                        width: "2rem",
                                                        display: "flex",
                                                        justifyContent: "center",
                                                        alignItems: "center"
                                                    }}><FaAngleDoubleRight/></span>
                            </div>
                        </div>
                    </div>
                </MediaQuery>
                <MediaQuery maxWidth={460} >
                    <div className=" notion-breadcrumbs  col-12 col-md-12 d-flex align-items-center"  >
                        <Link to="/uxr-communities" style={{textDecoration: "none", fontSize:"16px"}}>
                            <span className={window.location.pathname==="/uxr-communities" ? "bread-active" : ""}> Communities </span>
                        </Link>
                        <Link to="/uxr-conferences" style={{ textDecoration: "none", fontSize:"16px"}}>
                                            <span className={window.location.pathname==="/uxr-conferences" ? "bread-active" : ""} >
                                                Conferences
                                            </span>

                        </Link>
                        <Link to="/research-tools" style={{textDecoration: "none", fontSize:"16px"}}>
                                                <span className={window.location.pathname==="/research-tools" ? "bread-active" : ""}  >
                                                    Tools
                                                </span>  </Link>
                        <Link to="/research-vocabulary" style={{textDecoration: "none", fontSize:"16px"}}>
                                <span className={window.location.pathname==="/research-vocabulary" ? "bread-active" : ""} >
                                Vocabulary
                                </span>
                        </Link>
                        <Link to="/volunteer-opportunities" style={{textDecoration: "none", fontSize:"16px"}}>
                                <span className={window.location.pathname==="/volunteer-opportunities" ? "bread-active" : ""} >
                                Volunteer Opportunities
                                </span>
                        </Link>
                        <Link to="/ux-podcasts" style={{textDecoration: "none", fontSize:"16px"}}>
                                <span className={window.location.pathname==="/ux-podcasts" ? "bread-active" : ""}>
                                Podcasts
                                </span>
                        </Link>
                        <Link to="/research-books" style={{textDecoration: "none", fontSize:"16px"}}>
                                <span className={window.location.pathname==="/research-books" ? "bread-active" : ""} >
                                Books
                                </span>
                        </Link>
                        <Link to="/uxr-courses" style={{textDecoration: "none", fontSize:"16px"}}>
                                <span className={window.location.pathname==="/uxr-courses" ? "bread-active" : ""}>
                                Courses
                                </span>
                        </Link>
                        <Link to="/uxr-methods" style={{textDecoration: "none", fontSize:"16px"}}>
                                <span className={window.location.pathname==="/uxr-methods" ? "bread-active" : ""}  >
                                Methods
                                </span>
                        </Link>
                        <Link to="/rb-originals" style={{textDecoration: "none", fontSize:"16px"}}>
                                <span className={window.location.pathname==="/rb-originals" ? "bread-active" : ""}  >
                               Templates
                                </span>
                        </Link>
                    </div>
                </MediaQuery>
                <MediaQuery  maxWidth={1200} minWidth={461} >
                    <div className=" notion-breadcrumbs  col-12 col-md-12 d-flex align-items-center"  >
                        <Link to="/uxr-communities" style={{textDecoration: "none", fontSize:"16px"}}>
                            <span className={window.location.pathname==="/uxr-communities" ? "bread-active" : ""}> Communities </span>
                        </Link>
                        <Link to="/uxr-conferences" style={{ textDecoration: "none", fontSize:"16px"}}>
                                            <span className={window.location.pathname==="/uxr-conferences" ? "bread-active" : ""} >
                                                Conferences
                                            </span>

                        </Link>
                        <Link to="/research-tools" style={{textDecoration: "none", fontSize:"16px"}}>
                                                <span className={window.location.pathname==="/research-tools" ? "bread-active" : ""}  >
                                                    Tools
                                                </span>  </Link>
                        <Link to="/research-vocabulary" style={{textDecoration: "none", fontSize:"16px"}}>
                                <span className={window.location.pathname==="/research-vocabulary" ? "bread-active" : ""} >
                                Vocabulary
                                </span>
                        </Link>
                        <Link to="/volunteer-opportunities" style={{textDecoration: "none", fontSize:"16px"}}>
                                <span className={window.location.pathname==="/volunteer-opportunities" ? "bread-active" : ""} >
                                Volunteer Opportunities
                                </span>
                        </Link>
                        <Link to="/ux-podcasts" style={{textDecoration: "none", fontSize:"16px"}}>
                                <span className={window.location.pathname==="/ux-podcasts" ? "bread-active" : ""}>
                                Podcasts
                                </span>
                        </Link>
                        <Link to="/research-books" style={{textDecoration: "none", fontSize:"16px"}}>
                                <span className={window.location.pathname==="/research-books" ? "bread-active" : ""} >
                                Books
                                </span>
                        </Link>
                        <Link to="/uxr-courses" style={{textDecoration: "none", fontSize:"16px"}}>
                                <span className={window.location.pathname==="/uxr-courses" ? "bread-active" : ""}>
                                Courses
                                </span>
                        </Link>
                        <Link to="/uxr-methods" style={{textDecoration: "none", fontSize:"16px"}}>
                                <span className={window.location.pathname==="/uxr-methods" ? "bread-active" : ""}  >
                                Methods
                                </span>
                        </Link>
                    </div>

                </MediaQuery>


            </>
        );
    }
}
export default ResourcesNav