import React, {Component} from "react";
import axios from "axios";
import buildAPIUrl from "../../../../Services/UrlBuilder";
import {Carousel} from "react-bootstrap";
import MediaQuery from "react-responsive";
import SkeletonImage from "../Skeletons/SkeletonImage";
import image1 from "../../../../assets/Companies' logos/Company logo.png"
import image2 from "../../../../assets/Companies' logos/Company logo-1.png"
import image3 from "../../../../assets/Companies' logos/Company logo-2.png"
import image4 from "../../../../assets/Companies' logos/Company logo-3.png"
import image5 from "../../../../assets/Companies' logos/Company logo-4.png"
import image6 from "../../../../assets/Companies' logos/Company logo-5.png"
import image7 from "../../../../assets/Companies' logos/Amazon.png"
import image8 from "../../../../assets/Companies' logos/AnswerLab.png"
import image9 from "../../../../assets/Companies' logos/Microsoft svg.png"
import image10 from "../../../../assets/Companies' logos/image 12.png"
import image11 from "../../../../assets/Companies' logos/image 13.png"
import image12 from "../../../../assets/Companies' logos/image 1433.png"
import image13 from "../../../../assets/Companies' logos/image 1434.png"
import image14 from "../../../../assets/Companies' logos/logo_standard.png"
import image15 from "../../../../assets/Companies' logos/Klaviyo.png"
import image16 from "../../../../assets/Companies' logos/OKCoin - png (Traced).png"
import image17 from "../../../../assets/Companies' logos/Pinterest.png"
import image18 from "../../../../assets/Companies' logos/SEMrush.png"
import Marquee from "react-fast-marquee";

class Partners extends Component{
    state = {
        partners:[],
        loading:true,
    }
    componentDidMount() {
        //Get partner images
        const response = axios.get(buildAPIUrl('v1/partners/')).then((res)=>{
            this.setState({
                partners:res.data,
                loading:false
            })
        }).catch((error)=>{

        })

    }

    render() {
        return (
            <>
                <MediaQuery minWidth={1200}>

                        <div className={"container-fluid d-flex justify-content-around"} style={{marginTop:"2.938rem"}}>
                            <Marquee>
                            <img src={image1} style={{marginRight:"5.5rem"}}/>
                            <img src={image2} style={{marginRight:"5.5rem"}}/>
                            <img src={image3} style={{marginRight:"5.5rem"}}/>
                            <img src={image4} style={{marginRight:"5.5rem"}}/>
                            <img src={image5} style={{marginRight:"5.5rem"}}/>
                            <img src={image6} style={{marginRight:"5.5rem"}}/>
                            <img src={image7} style={{marginRight:"5.5rem"}}/>
                            <img src={image8} style={{marginRight:"5.5rem"}}/>
                            <img src={image9} style={{marginRight:"5.5rem"}}/>
                            <img src={image10} style={{marginRight:"5.5rem"}}/>
                            <img src={image11} style={{marginRight:"5.5rem"}}/>
                            <img src={image12} style={{marginRight:"5.5rem"}}/>
                            <img src={image13} style={{marginRight:"5.5rem"}}/>
                            <img src={image14} style={{marginRight:"5.5rem"}}/>
                            <img src={image15} style={{marginRight:"5.5rem"}}/>
                            <img src={image16} style={{marginRight:"5.5rem"}}/>
                            <img src={image17} style={{marginRight:"5.5rem"}}/>
                            <img src={image18} style={{marginRight:"5.5rem"}}/>
                            </Marquee>
                        </div>


                  {/*  {
                        this.state.loading ? <>
                        <SkeletonImage />
                        </> : <>
                            {
                                this.state.partners  ? <>
                                    <div className={"row"}>
                                        {
                                            this.state.partners.map((partner)=>(
                                                <div className={"col-md-4 mt-5"}>
                                                    <img width={"200"} height={"100"} alt={`partner image ${partner.id}`} src={partner.image} style={{width:"100%", height:"auto"}}/>
                                                </div>
                                            ))
                                        }
                                    </div>
                                </> : <></>
                            }
                        </>
                    }*/}

                </MediaQuery>
                <MediaQuery minWidth={461} maxWidth={1200}>
                    <div className={"container-fluid d-flex justify-content-around"} style={{marginTop:"2.938rem"}}>
                        <Marquee>
                            <img src={image1} style={{marginRight:"5.5rem"}}/>
                            <img src={image2} style={{marginRight:"5.5rem"}}/>
                            <img src={image3} style={{marginRight:"5.5rem"}}/>
                            <img src={image4} style={{marginRight:"5.5rem"}}/>
                            <img src={image5} style={{marginRight:"5.5rem"}}/>
                            <img src={image6} style={{marginRight:"5.5rem"}}/>
                            <img src={image7} style={{marginRight:"5.5rem"}}/>
                            <img src={image8} style={{marginRight:"5.5rem"}}/>
                            <img src={image9} style={{marginRight:"5.5rem"}}/>
                            <img src={image10} style={{marginRight:"5.5rem"}}/>
                            <img src={image11} style={{marginRight:"5.5rem"}}/>
                            <img src={image12} style={{marginRight:"5.5rem"}}/>
                            <img src={image13} style={{marginRight:"5.5rem"}}/>
                            <img src={image14} style={{marginRight:"5.5rem"}}/>
                            <img src={image15} style={{marginRight:"5.5rem"}}/>
                            <img src={image16} style={{marginRight:"5.5rem"}}/>
                            <img src={image17} style={{marginRight:"5.5rem"}}/>
                            <img src={image18} style={{marginRight:"5.5rem"}}/>
                        </Marquee>
                    </div>
                </MediaQuery>
                <MediaQuery maxWidth={460}>
                    <div className={"container-fluid d-flex justify-content-around p-0"} style={{marginTop:"2.938rem"}}>
                        <Marquee>
                            <img src={image1} style={{marginRight:"5.5rem"}}/>
                            <img src={image2} style={{marginRight:"5.5rem"}}/>
                            <img src={image3} style={{marginRight:"5.5rem"}}/>
                            <img src={image4} style={{marginRight:"5.5rem"}}/>
                            <img src={image5} style={{marginRight:"5.5rem"}}/>
                            <img src={image6} style={{marginRight:"5.5rem"}}/>
                            <img src={image7} style={{marginRight:"5.5rem"}}/>
                            <img src={image8} style={{marginRight:"5.5rem"}}/>
                            <img src={image9} style={{marginRight:"5.5rem"}}/>
                            <img src={image10} style={{marginRight:"5.5rem"}}/>
                            <img src={image11} style={{marginRight:"5.5rem"}}/>
                            <img src={image12} style={{marginRight:"5.5rem"}}/>
                            <img src={image13} style={{marginRight:"5.5rem"}}/>
                            <img src={image14} style={{marginRight:"5.5rem"}}/>
                            <img src={image15} style={{marginRight:"5.5rem"}}/>
                            <img src={image16} style={{marginRight:"5.5rem"}}/>
                            <img src={image17} style={{marginRight:"5.5rem"}}/>
                            <img src={image18} style={{marginRight:"5.5rem"}}/>
                        </Marquee>
                    </div>
                </MediaQuery>


            </>
        );
    }
}
export default Partners