import React, {Component} from "react";
import Header from "../Landing/Header";
import {HiSearch} from "react-icons/hi";
import axios from "axios";
import buildAPIUrl from "../../../Services/UrlBuilder";
import ContentEditable from "react-contenteditable";
import Skeleton from "react-loading-skeleton";
import {FaAngleDoubleLeft, FaAngleDoubleRight, FaBookmark, FaRegBookmark, FaRegCopy} from "react-icons/fa";
import {copyLink, saveNotionArticle} from "../ResultsCategories/CategoriesFunctions";
import SkeletonSearchResult from "../Landing/Skeletons/SkeletonSearchResult";
import MediaQuery from "react-responsive";
import searchicon from "../../../assets/Frame 39.png";
import LoginModal from "../Modals/LoginModal";
import {Modal, ModalFooter} from "react-bootstrap";
import Logo from "../../../assets/research-bookmark-logotype-one-color-rgb.svg";
import logout from "../../../assets/logout.png";
import {
    MdBorderClear,
    MdCancel,
    MdCancelPresentation,
    MdClear,
    MdOpenInFull,
    MdOpenInNew,
    MdSettings
} from "react-icons/md";
import Footer from "../Landing/Footer";

class MainNotion extends Component{
    state = {
        q:"",
        links:[],
        phase:2,
        articles:[],
        searcht:"",
        tags:[],
        tagid:null,
        tagname:"",
        copy:false,
        saved:false,
        exists:"",
        starting:"",
        presenting:"",
        analyzing:"",
        conducting:"",
        loading:true,
        offset:0,
        phasetitle:"Planning Research",
        filter:"",
        token:localStorage.getItem('token'),
        itemscount:80,
        displaytags:false,
        url:"",
        title:"",
        content:"",
        showmodal:false,
        result:[],
        previewcontent:"",
        generatingpreview:true,
        previewname:"",
        previewurl:"",
        chatgptsecret:process.env.REACT_APP_CHATGPT_SECRET,
    }
    handleSearch = (e) => {
        e.preventDefault()
        localStorage.setItem('article_query', this.state.q)
        window.location.assign('/articles-search-results')
    }
    handleInput = (e) =>{
        this.setState({
            filter:e.target.value,
            [e.target.name]: e.target.value
        })
    }
    handleDisplayTags = () => {
        if(this.state.displaytags) {
            this.setState({
                displaytags:false
            })
        } else {
            this.setState({
                displaytags:true
            })
        }
    }
   componentDidMount() {
    const token = localStorage.getItem('token');
     if(!token){
        window.location.assign('/');
     }
       window.scrollTo(0,0)
         //Get the list of phases
       axios.get(buildAPIUrl('v1/article_phases/')).then((res)=>{
           console.log(res.data)
            this.setState({
                links:res.data,
                starting:res.data[1],
                presenting:res.data[0],
                analyzing:res.data[2],
                conducting:res.data[3],
            })
        })
        //Get the articles for each phase. By default, the phase id is 0.
        this.setState({
            phase:parseInt(this.props.match.params.id),
            phasetitle:this.props.match.params.title
        })
        const phaseid =  this.props.match.params.id
        axios.get(buildAPIUrl(`v1/articles/?phase=${phaseid}&limit=20&offset=${this.state.offset}`)).then((res)=>{
            this.setState({
                articles:res.data.results,
                loading:false,
            })

            //Scroll to the active phase
            const section = document.querySelector( '.bread-active' );
            setTimeout(()=>{
                section.scrollIntoView({ behavior: 'smooth', block: 'nearest', inline: 'center' })
            }, 1000)
        })
        //Get the list of tags
        axios.get(buildAPIUrl(`v1/article_phases/${phaseid}`)).then((res)=>{
            this.setState({
                tags:res.data.tags
            })
        })


    }
    handleBreadCrumbs = async (id, title, url)=>{
        //direct user to the new articles
        window.location.assign(`/articles/${id}/${url}`)
    }
    handleTags = async (tagid, tagname)=>{
       await this.setState({
            loading:true,
            offset:0,
            tagname:tagname,
           displaytags:false
        })
        //Get the articles for each phase. By default, the phase id is 0.
        const response1  = await axios.get(buildAPIUrl(`v1/articles/?tag_name=${tagname}&limit=20&offset=${this.state.offset}`)).then((res)=>{
           console.log("tags", res)
            this.setState({
                articles:res.data.results,
                loading:false,
                itemscount:res.data.count
            })
        })
      this.setState({
            tagid:tagid,
            tagname:tagname
        })
        const section = document.querySelector( '.active-tag' );
        const section1 = document.querySelector( '.bread-active' );
        setTimeout(()=>{
            section.scrollIntoView({ behavior: 'smooth', block: 'nearest', inline: 'center' })
            section1.scrollIntoView({ behavior: 'smooth', block: 'nearest', inline: 'center' })
        }, 1000)
    }
/*-----Copy results link------*/
    handleCopyLink = (result)=>{
        copyLink(result, (copystatus)=>{
            this.setState({
                copy:copystatus
            })
        })

    }
    /*-----Bookmark Result------*/
    handleSaveSearch = (result, bookmarktype)=>{
        saveNotionArticle(result, bookmarktype, (savestatus)=>{
           this.setState({
               saved:savestatus
           })
        }, (error)=>{
           this.setState({
               exists:error
           })
            setInterval(()=>{
                this.setState({
                    exists:""
                })
            }, 3000)
            this.props.handleSave(false, error)
        })
    }
    handleModalOpen = (result, logtype)=>{
       this.setState({
           show: true,
           siteid:result,
           logintype:logtype
       })
    }
    handleModalClose = () => {
        this.setState({
          show:false
        })
    }
    handleNext = async (page, pageno)=>{
        await this.setState({
            offset:pageno
        })
        if(this.state.tagid){
            axios.get(buildAPIUrl(`v1/articles/?tag_name=${this.state.tagname}&limit=20&offset=${this.state.offset}`)).then((res)=>{
                this.setState({
                    articles:res.data.results,
                    loading:false
                })
            })
        } else  {
             axios.get(buildAPIUrl(`v1/articles/?phase=${this.state.phase}&limit=20&offset=${this.state.offset}`)).then((res)=>{
                this.setState({
                    articles:res.data.results,
                    loading:false
                })
            })
        }

        window.scrollTo(0,0)
    }

    //Scrolling buttons for the overflow
    scrollRight = async ()=>{
        const right = document.querySelector(".tags-list");
       await right.scrollBy(200, 0);
    }
    scrollLeft= async ()=>{
        const left = document.querySelector(".tags-list");
       await left.scrollBy(-200, 0);
    }

    //Show content preview
    handlePreview = async (result) => {
        this.setState({
            preview:true,
            previewname:result.title,
            previewurl:result.url
        })
        //Get response from chat gpt
        await axios.post('https://api.openai.com/v1/engines/text-davinci-002/completions',
            {
                prompt: `Please summarize the content of this URL in a list of 4 paragraphs without numbering: ${result.url}`,
                max_tokens: 500
            },
            {
                headers: {
                    'Content-Type': 'application/json',
                    'Authorization': `Bearer ${this.state.chatgptsecret}`
                }
            }).then((res)=>{
            setTimeout(()=>{
                this.setState({
                    previewcontent: res.data.choices[0].text.slice(0,res.data.choices[0].text.length + 1),
                    generatingpreview:false
                })
            }, 100)


        });
    }
    //Hide content preview
    reghandleClose = ()=>{
        this.setState({
            preview:false,
            previewcontent:"",
            generatingpreview:true,
        })
    }

    render() {
        return (
            <>
                <Header />
                <LoginModal openLoginModal={this.handleModalOpen}
                            closeLoginModal={this.handleModalClose}
                            show={this.state.show} type={this.state.logintype}/>
                <Modal size="lg" style={{borderRadius:"2px"}} centered show={this.state.preview}  onHide={this.reghandleClose} >

                    <Modal.Body style={{overflowX:"hidden", overflowY:"hidden", padding:"2rem"}}>
                        <div className={"row w-100"}>
                            <div className={"col-md-12 col-2 d-flex justify-content-end"}>
                                <span onClick={this.reghandleClose} className={"d-flex justify-content-center align-items-center"} style={{color:"#000000", borderRadius:"8px", height:"34px", width:"34px",cursor:"pointer", fontSize:"34px"}}><MdClear /></span>
                            </div>
                            <div className={"col-md-12 d-flex justify-content-center"}>
                                <h1 className={"summary-title text-center"} style={{fontWeight:"400", fontSize:"28px", lineHeight:"110%", color:"#00944D", marginTop:"1rem"}}>{this.state.previewname.substr(0, 100)}</h1>
                            </div>

                        </div>
                        <div className="row content-preview" style={{borderRadius:"2px", padding:"1rem"}}>
                            {
                                this.state.generatingpreview ? <>
                                    <div className={"container d-flex justify-content-center text-center"}>
                                        <div>
                                            <MdSettings style={{fontSize:"45px"}} />
                                            <p className={"generating"} style={{marginTop:"1rem"}}>Generating a summary...</p>
                                        </div>
                                    </div>

                                </> : <>
                                    <div style={{color:"black", lineHeight:"150%", }}>
                                        <p className={"summary"} style={{whiteSpace:"pre-line", fontSize:"16px", lineHeight:"22px", marginTop:"-2.5rem"}}>{this.state.previewcontent}</p>
                                    </div>
                                    <div className={"container-fluid mt-3 d-flex justify-content-end"}>
                                        <a href={this.state.previewurl} target={"_blank"} className={"btn"} style={{background:"#522FFF", color:"white"}}>
                                            Go to site <MdOpenInNew />
                                        </a>
                                    </div>
                                </>
                            }
                        </div>
                    </Modal.Body>
                    <ModalFooter style={{border:"none"}}>

                    </ModalFooter>

                </Modal>
                <MediaQuery minWidth={1200}>
                    <>

                        <div className={"row"} style={{marginTop:"5rem"}}>
                            <div className={"col-md-2 col-1"}>

                            </div>
                            <div className={"col-md-8 col-11 p-0"}>
                                {/*  Search box*/}
                                <div className="search-section col-12 col-md-12 mt-4" style={{padding:"0", background:"white"}}>
                                    <div className="row d-flex align-items-center ">
                                        <div className=" col-12 col-md-12 d-flex justify-content-center">
                                            <form onSubmit={this.handleSearch} style={{width:"100%"}} autoComplete="off">
                                                <div className="form-input border" style={{borderRadius:"100px", paddingLeft:"1rem", paddingRight:"1.5rem"}}>
                                                             <span className="input-group-addon d-flex align-items-center" style={{zIndex:"1000", paddingLeft:"2%"}}> <img src={searchicon}/></span>
                                                    <input className="search-field-text1 mob" type="text" name="q" onChange={this.handleInput} value={this.state.q}
                                                           style={{width: "100%", paddingLeft:"2%"}} placeholder="Search for our resources"/>

                                                </div>
                                            </form>
                                        </div>
                                    </div>

                                </div>
                                {/*  Breadcrumbs*/}

                            </div>
                        </div>
                        <div className={"row border-bottom"}>
                            <div className={"col-md-2 col-1"}>

                            </div>
                            <div className={"col-md-8 col-11 p-0"}>
                                <div className={"container notion-breadcrumbs "}>
                                    <div className={" "}>
                                        <ul>
                                         {/*   {
                                                this.state.links.map((link1, key)=>(
                                                    <>
                                                        <li onClick={()=>this.handleBreadCrumbs(link1.id, link1.name, "planning-research")} style={{cursor:"pointer"}}>
                                            <span className={this.state.phase===2 ?  "bread-active" : ""} >
                                                   {"0" + parseInt(key +1 )} -  {link1.name}
                                            </span>
                                                        </li>
                                                    </>
                                                ))
                                            }*/}
                                            {
                                                this.state.starting ? <>
                                                    <li onClick={()=>this.handleBreadCrumbs(2, "Planning Research", "planning-research")} style={{cursor:"pointer"}}>
                                            <span className={this.state.phase===2 ?  "bread-active" : ""} >
                                                   {"0" + 1} -  Planning Research
                                            </span>
                                                    </li>
                                                </> : <></>
                                            }
                                            {
                                                this.state.conducting ? <>
                                                    <li onClick={()=>this.handleBreadCrumbs(4, "Conducting Research", "conducting-research")} style={{cursor:"pointer"}}>
                                            <span className={this.state.phase===4 ?  "bread-active" : ""} >
                                                   {"0" + 2} -  Conducting Research
                                            </span>
                                                    </li>
                                                </> : <></>
                                            }
                                            {
                                                this.state.analyzing ? <>
                                                    <li onClick={()=>this.handleBreadCrumbs(3, "Analyzing Research", "analyzing-research")} style={{cursor:"pointer"}}>
                                            <span className={this.state.phase===3 ?  "bread-active" : ""} >
                                                   {"0" + 3} -  Analyzing Research
                                            </span>
                                                    </li>
                                                </> : <></>


                                            }
                                            {
                                                this.state.presenting ? <>
                                                    <li onClick={()=>this.handleBreadCrumbs(1, "Presenting Research", "presenting-research")} style={{cursor:"pointer"}}>
                                            <span className={this.state.phase===1 ?  "bread-active" : ""} >
                                                   {"0" + 4} -  Presenting Research
                                            </span>
                                                    </li>
                                                </> : <>

                                                </>
                                            }

                                        </ul>
                                    </div>

                                </div>

                            </div>
                        </div>

                        <div className={"row p-0"}>
                            <div className={"col-md-2 col-1"}>

                            </div>
                            <div className={"col-md-8 col-11 p-0"}>
                                <div className={"tags-container"}>
                                    {
                                        this.state.loading ? " " :
                                            <div className={"row"}>
                                                <div
                                                    className={"col-md-1 d-flex align-items-center m-0 p-0 justify-content-center"} style={{ boxShadow:"9px 2px 12px -15px rgba(0,0,0,0.5)"}}>
                                                    <span onClick={this.scrollLeft} style={{
                                                        cursor: "pointer",
                                                        border: "1px solid silver",
                                                        borderRadius: "50%",
                                                        height: "2rem",
                                                        width: "2rem",
                                                        display: "flex",
                                                        justifyContent: "center",
                                                        alignItems: "center",
                                                        marginTop:"1rem"
                                                    }}><FaAngleDoubleLeft /></span>
                                                </div>
                                                <div className={"col-md-10 p-0 m-0 px-3"}>

                                                    <ul className={"tags-list"}
                                                        style={{padding: "0", marginTop: "2.188rem"}}>
                                                        {
                                                            this.state.loading ?
                                                                <>
                                                                    {
                                                                        [1, 2, 3, 4, 5, 6, 7].map((n) =>
                                                                            <SkeletonSearchResult
                                                                                key={n}/>)
                                                                    }

                                                                </> : <>
                                                                    {
                                                                        this.state.tags.map((tag) => (
                                                                            <li style={{cursor: "pointer"}}
                                                                                onClick={() => this.handleTags(tag.id, tag.name)}
                                                                                className={this.state.tagid === tag.id ? "active-tag" : ""}>{tag.name}</li>
                                                                        ))
                                                                    }
                                                                </>
                                                        }

                                                    </ul>
                                                </div>
                                                <div className={"col-md-1 d-flex align-items-center"} style={{ boxShadow:"-9px 2px 12px -15px rgba(0,0,0,0.5)"}}>
                                                    <span onClick={this.scrollRight} style={{
                                                        cursor: "pointer",
                                                        border: "1px solid silver",
                                                        borderRadius: "50%",
                                                        height: "2rem",
                                                        width: "2rem",
                                                        display: "flex",
                                                        justifyContent: "center",
                                                        alignItems: "center",
                                                        marginTop:"1rem"
                                                    }}>   <FaAngleDoubleRight /> </span>
                                                </div>
                                            </div>
                                    }
                                </div>
                            </div>
                        </div>

                        {/*  Results */}
                        <div className={"row" } style={{marginTop:"1.75rem"}}>
                            <div className={"col-md-2"}>

                            </div>
                            <div className={"col-md-8"}>
                                <div className={"col-md-12"}>
                                    <div className={"row"}>

                                        <div className={"col-md-8"}>
                                            {
                                                this.state.loading ?
                                                    <>
                                                        {
                                                            [1, 2, 3, 4, 5, 6, 7].map((n) => <SkeletonSearchResult
                                                                key={n}/>)
                                                        }

                                                    </> : <>
                                                        <h1 className={"d-flex align-items-center"} style={{fontSize:"18px", fontWeight:"600"}}>
                                                            {this.state.tagname ?
                                                                <>
                                                                    <h5 style={{textTransform:"capitalize"}}> {this.state.tagname}</h5>
                                                                </>
                                                                : <>
                                                                <h5 style={{textTransform:"capitalize"}}>
                                                                    {
                                                                this.state.phasetitle.replace('-', " ")
                                                                    } </h5>

                                                                </>
                                                            }
                                                        </h1>
                                                        {localStorage.getItem('token') ?
                                                        <>
                                                            {
                                                                this.state.articles.map((result)=>(
                                                                    <>
                                                                        <div style={{marginTop:"2.188rem"}}>
                                                                            <a onClick={()=>this.handlePreview(result)} target={"_blank"}><h1
                                                                                className="result-url mt-2 mb-2">{result.url.substr(0, 100)}</h1>
                                                                            </a>
                                                                            <a  onClick={()=>this.handlePreview(result)} target={"_blank"}><h1
                                                                                className="result-name mt-2 mb-2">
                                                                                <ContentEditable
                                                                                    innerRef={result.title}
                                                                                    html={result.title.substr(0, 150).replace("[", " ").toLowerCase().replace(this.state.searcht.toLowerCase(), "<b style='font-weight: 900'>" + this.state.searcht.toLowerCase() + "</b>")} // innerHTML of the editable div
                                                                                    disabled={true}       // use true to disable editing
                                                                                    onChange={this.handleChange} // handle innerHTML change
                                                                                    tagName='article' // Use a custom HTML tag (uses a div by default)
                                                                                />
                                                                            </h1>
                                                                            </a>
                                                                            <p className="result-snippet">
                                                                                <ContentEditable
                                                                                    innerRef={result.content + "..."}
                                                                                    html={result.content.substr(0, 150).replace("[", " ").replace(this.state.searcht.toLowerCase(), "<b style='font-weight: 900'>" + this.state.searcht.toLowerCase() + "</b>") ||
                                                                                        <Skeleton/>} // innerHTML of the editable div
                                                                                    disabled={true}       // use true to disable editing
                                                                                    onChange={this.handleChange} // handle innerHTML change
                                                                                    tagName='article' // Use a custom HTML tag (uses a div by default)
                                                                                />

                                                                            </p>
                                                                            {
                                                                                this.state.token ? <span className="save save-icon-onboard"
                                                                                                         onClick={() => this.handleSaveSearch(result, "Notion Tags")}> Save <FaRegBookmark
                                                                                        className="save-icon"/></span> :
                                                                                    <span className="save save-icon-onboard"
                                                                                          onClick={() => this.handleModalOpen(result, "1")}> Save <FaRegBookmark/> </span>
                                                                            }

                                                                            <span className="save" onMouseDown={() => {
                                                                                this.handleCopyLink(result.url)
                                                                            }}> Copy link <FaRegCopy className="save-icon"/></span>
                                                                        </div>
                                                                    </>
                                                                ))
                                                            }
                                                        </>
                                                        :
                                                            <>
                                                                {
                                                                    this.state.articles.slice(0,1).map((result)=>(
                                                                        <>
                                                                            <div style={{marginTop:"2.188rem"}}>
                                                                                <a onClick={()=>this.handlePreview(result)} target={"_blank"}><h1
                                                                                    className="result-url mt-2 mb-2">{result.url.substr(0, 100)}</h1>
                                                                                </a>
                                                                                <a  onClick={()=>this.handlePreview(result)} target={"_blank"}><h1
                                                                                    className="result-name mt-2 mb-2">
                                                                                    <ContentEditable
                                                                                        innerRef={result.title}
                                                                                        html={result.title.substr(0, 150).replace("[", " ").toLowerCase().replace(this.state.searcht.toLowerCase(), "<b style='font-weight: 900'>" + this.state.searcht.toLowerCase() + "</b>")} // innerHTML of the editable div
                                                                                        disabled={true}       // use true to disable editing
                                                                                        onChange={this.handleChange} // handle innerHTML change
                                                                                        tagName='article' // Use a custom HTML tag (uses a div by default)
                                                                                    />
                                                                                </h1>
                                                                                </a>
                                                                                <p className="result-snippet">
                                                                                    <ContentEditable
                                                                                        innerRef={result.content + "..."}
                                                                                        html={result.content.substr(0, 150).replace("[", " ").replace(this.state.searcht.toLowerCase(), "<b style='font-weight: 900'>" + this.state.searcht.toLowerCase() + "</b>") ||
                                                                                            <Skeleton/>} // innerHTML of the editable div
                                                                                        disabled={true}       // use true to disable editing
                                                                                        onChange={this.handleChange} // handle innerHTML change
                                                                                        tagName='article' // Use a custom HTML tag (uses a div by default)
                                                                                    />

                                                                                </p>
                                                                                {
                                                                                    this.state.token ? <span className="save save-icon-onboard"
                                                                                                             onClick={() => this.handleSaveSearch(result, "Notion Tags")}> Save <FaRegBookmark
                                                                                            className="save-icon"/></span> :
                                                                                        <span className="save save-icon-onboard"
                                                                                              onClick={() => this.handleModalOpen(result, "1")}> Save <FaRegBookmark/> </span>
                                                                                }

                                                                                <span className="save" onMouseDown={() => {
                                                                                    this.handleCopyLink(result.url)
                                                                                }}> Copy link <FaRegCopy className="save-icon"/></span>
                                                                            </div>
                                                                        </>
                                                                    ))
                                                                }
                                                                {
                                                                    this.state.articles.slice(1).map((result)=>(
                                                                        <>
                                                                            <div style={{marginTop:"2.188rem"}}>
                                                                                <a onClick={()=>this.handleModalOpen()} target={"_blank"}><h1
                                                                                    className="result-url mt-2 mb-2">{result.url.substr(0, 100)}</h1>
                                                                                </a>
                                                                                <a  onClick={()=>this.handleModalOpen()} target={"_blank"}><h1
                                                                                    className="result-name mt-2 mb-2">
                                                                                    <ContentEditable
                                                                                        innerRef={result.title}
                                                                                        html={result.title.substr(0, 150).replace("[", " ").toLowerCase().replace(this.state.searcht.toLowerCase(), "<b style='font-weight: 900'>" + this.state.searcht.toLowerCase() + "</b>")} // innerHTML of the editable div
                                                                                        disabled={true}       // use true to disable editing
                                                                                        onChange={this.handleChange} // handle innerHTML change
                                                                                        tagName='article' // Use a custom HTML tag (uses a div by default)
                                                                                    />
                                                                                </h1>
                                                                                </a>
                                                                                <p className="result-snippet">
                                                                                    <ContentEditable
                                                                                        innerRef={result.content + "..."}
                                                                                        html={result.content.substr(0, 150).replace("[", " ").replace(this.state.searcht.toLowerCase(), "<b style='font-weight: 900'>" + this.state.searcht.toLowerCase() + "</b>") ||
                                                                                            <Skeleton/>} // innerHTML of the editable div
                                                                                        disabled={true}       // use true to disable editing
                                                                                        onChange={this.handleChange} // handle innerHTML change
                                                                                        tagName='article' // Use a custom HTML tag (uses a div by default)
                                                                                    />

                                                                                </p>
                                                                                {
                                                                                    this.state.token ? <span className="save save-icon-onboard"
                                                                                                             onClick={() => this.handleSaveSearch(result, "Notion Tags")}> Save <FaRegBookmark
                                                                                            className="save-icon"/></span> :
                                                                                        <span className="save save-icon-onboard"
                                                                                              onClick={() => this.handleModalOpen(result, "1")}> Save <FaRegBookmark/> </span>
                                                                                }

                                                                                <span className="save" onMouseDown={() => {
                                                                                    this.handleCopyLink(result.url)
                                                                                }}> Copy link <FaRegCopy className="save-icon"/></span>
                                                                            </div>
                                                                        </>
                                                                    ))
                                                                }
                                                            </>
                                                        }

                                                    </>
                                            }
                                         <nav className="d-flex justify-content-center">
                                                {
                                                    this.state.loading ? "" :
                                                        <ul className="pagination">
                                                              <li className={this.state.offset === "0" || this.state.offset === 0  ? "page-item active" : "page-item"}>
                                                                    <a className="page-link"
                                                                       onClick={() => this.handleNext("page", 0)}>1</a>
                                                                </li>
                                                            {
                                                                this.state.itemscount > 40 ?
                                                                    <li className={this.state.offset === 20 ? "page-item active" : "page-item"}>
                                                                        <a className="page-link"
                                                                           onClick={() => this.handleNext("page", 20)}>2</a>
                                                                    </li>
                                                                    : ""
                                                            }
                                                            {
                                                                this.state.itemscount > 60 ?
                                                            <li className={this.state.offset === 40 ? "page-item active" : "page-item"}>
                                                                    <a className="page-link"
                                                                       onClick={() => this.handleNext("page", 40)}>3</a>
                                                                </li> : ""
                                                            }
                                                            {
                                                                this.state.itemscount > 80 ?
                                                                    <li className={this.state.offset === 60 ? "page-item active" : "page-item"}>
                                                                <a className="page-link"
                                                                   onClick={() => this.handleNext("page", 60)}>4</a>
                                                            </li> : ""
                                                            }
                                                            {/*<li className={this.state.offset === 80 ? "page-item active" : "page-item"}>
                                                                <a className="page-link"
                                                                   onClick={() => this.handleNext("page", 80)}>5</a>
                                                            </li>*/}
                                                            {/*  <li className="page-item"><a
                                                        className={this.state.offset < 4 ? "page-link" : "d-none"}
                                                        onClick={()=>this.handleNext("positive")}>Next</a></li>*/}
                                                        </ul>
                                                }
                                            </nav>

                                        </div>
                                    </div>
                                </div>

                            </div>
                            <div className={"col-md-2"}>
                                <div
                                    className={this.state.copy ? "alert alert-success position-fixed" : "d-none"}>
                                    <FaBookmark/> Link copied successfully
                                </div>
                                <div
                                    className={this.state.saved ? "alert alert-success position-fixed " : "d-none"}>
                                    <FaBookmark/> Article saved successfully
                                </div>
                                <div
                                    className={this.state.exists ? "alert alert-warning position-fixed" : "d-none"}>
                                    <FaBookmark/> Bookmark already exists
                                </div>
                            </div>
                        </div>
                    </>
                </MediaQuery>
                <MediaQuery maxWidth={460} className="mobile">
                    <>
                        <div className={"row container mobile notion"} style={{paddingRight:".2rem"}}>
                            <div className={"col-12"}>
                                {/*  Search box*/}
                                <div className="search-section p-0 col-12 col-md-12 mb-2 mt-4 ">
                                    <div className="row d-flex align-items-center ">
                                        <div className=" col-12 col-md-12 d-flex justify-content-center">
                                            <form onKeyUp={this.handleSearch} style={{width:"100%"}} autoComplete="off">
                                                <div className="form-input form-input-group border" style={{borderRadius:"100px", paddingLeft:"1rem", paddingRight:"1.5rem"}}>
                                                    <span className="input-group-text input-group-text-notion" id="basic-addon1" style={{padding:"0"}}><img src={searchicon}/></span>
                                                    <input className="form-control search-field-text1 mob" type="text" name="q" onChange={this.handleInput} value={this.state.q}
                                                           style={{width: "100%", border:"none"}} placeholder="Search for our resources"/>

                                                </div>
                                            </form>
                                        </div>
                                    </div>

                                </div>
                                {/*  Breadcrumbs*/}
                                <div className="container notion-breadcrumbs p-0">
                                    <ul>
                                        <li onClick={()=>this.handleBreadCrumbs(2, "Planning Research", "planning-research")} style={{cursor:"pointer"}}>
                                            <span className={this.state.phase===2 ?  "bread-active" : ""} >
                                                   {"0" + 1} -  Planning Research
                                            </span>
                                        </li>
                                        <li onClick={()=>this.handleBreadCrumbs(4, "Conducting Research", "conducting-research")} style={{cursor:"pointer"}}>
                                            <span className={this.state.phase===4 ?  "bread-active" : ""} >
                                                   {"0" + 2} -  Conducting Research
                                            </span>
                                        </li>
                                        <li onClick={()=>this.handleBreadCrumbs(3, "Analyzing Research", "analyzing-research")} style={{cursor:"pointer"}}>
                                            <span className={this.state.phase===3 ?  "bread-active" : ""} >
                                                   {"0" + 3} -  Analyzing Research
                                            </span>
                                        </li>
                                        <li onClick={()=>this.handleBreadCrumbs(1, "Presenting Research", "presenting-research")} style={{cursor:"pointer"}}>
                                            <span className={this.state.phase===1 ?  "bread-active" : ""} >
                                                   {"0" + 4} -  Presenting Research
                                            </span>
                                        </li>
                                    </ul>
                                </div>
                            </div>
                        </div>
                        {/*  Results */}
                        <div className={"row" } style={{marginTop:"1.438rem"}}>
                            <div className={"col-12"}>
                                <div className={"col-md-12"}>
                                    <div className={"row"}>
                                        <div className={"col-12 tags position-relative border-bottom"}>
                                            <div className={"tags-container border-top"}>
                                                <ul>
                                                    {
                                                        this.state.loading ?
                                                            <>
                                                                {
                                                                    [1, 2, 3, 4, 5, 6, 7].map((n) => <SkeletonSearchResult
                                                                        key={n}/>)
                                                                }

                                                            </> : <>
                                                                {
                                                                    this.state.tags.map((tag)=>(
                                                                        <li style={{cursor:"pointer"}} onClick={()=>this.handleTags(tag.id, tag.name)} className={this.state.tagid===tag.id ? "active-tag" : ""}>{tag.name}</li>
                                                                    ))
                                                                }
                                                            </>
                                                    }

                                                </ul>
                                            </div>
                                        </div>
                                        <div className={"col-12"}>
                                            <div className={" container"}>
                                                {
                                                    this.state.loading ?
                                                        <>
                                                            {
                                                                [1, 2, 3, 4, 5, 6, 7].map((n) => <SkeletonSearchResult
                                                                    key={n}/>)
                                                            }

                                                        </> : <>
                                                            <h1 className={"d-flex align-items-center mt-4"} style={{fontSize:"14px", fontWeight:"600"}}>
                                                                {this.state.tagname ?
                                                                    <>
                                                                        <h1 style={{textTransform:"capitalize"}}> {this.state.tagname}</h1>
                                                                    </>
                                                                    : <>
                                                                        <h1 style={{textTransform:"capitalize"}}>
                                                                            {
                                                                                this.state.phasetitle.replace('-', " ")
                                                                            } </h1>

                                                                    </>
                                                                }
                                                            </h1>
                                                            {
                                                                this.state.articles.map((result)=>(
                                                                    <>
                                                                        <div className={"result-section"} style={{marginBottom: "1.875rem", marginTop:".875rem"}}>
                                                                            <a onClick={()=>this.handlePreview(result)} target={"_blank"} style={{textDecorationColor:"#522FFF"}}><h1
                                                                                className="result-url mt-2 mb-2">{result.url.substr(0, 50) + "..."}</h1>
                                                                            </a>
                                                                            <a onClick={()=>this.handlePreview(result)} target={"_blank"} style={{textDecoration:"none"}}>
                                                                                <h1
                                                                                className="result-name mt-2 mb-2">
                                                                                <ContentEditable
                                                                                    innerRef={result.title}
                                                                                    html={result.title.substr(0, 50).replace("[", " ").toLowerCase().replace(this.state.searcht.toLowerCase(), "<b style='font-weight: 900'>" + this.state.searcht.toLowerCase() + "</b>") + "..." } // innerHTML of the editable div
                                                                                    disabled={true}       // use true to disable editing
                                                                                    onChange={this.handleChange} // handle innerHTML change
                                                                                    tagName='article' // Use a custom HTML tag (uses a div by default)
                                                                                />

                                                                            </h1>
                                                                            </a>
                                                                            <p className="result-snippet">
                                                                                <ContentEditable
                                                                                    innerRef={result.content + "..."}
                                                                                    html={result.content.substr(0, 150).replace("[", " ").replace(this.state.searcht.toLowerCase(), "<b style='font-weight: 900'>" + this.state.searcht.toLowerCase() + "</b>")  + "..." ||
                                                                                        <Skeleton/>} // innerHTML of the editable div
                                                                                    disabled={true}       // use true to disable editing
                                                                                    onChange={this.handleChange} // handle innerHTML change
                                                                                    tagName='article' // Use a custom HTML tag (uses a div by default)
                                                                                />

                                                                            </p>
                                                                            {
                                                                                this.state.token ? <span className="save save-icon-onboard"
                                                                                                         onClick={() => this.handleSaveSearch(result, "Notion Tags")}> Save <FaRegBookmark
                                                                                        className="save-icon"/></span> :
                                                                                    <span className="save save-icon-onboard"
                                                                                          onClick={() => this.handleModalOpen(result, "1")}> Save <FaRegBookmark/> </span>
                                                                            }

                                                                            <span className="save" onMouseDown={() => {
                                                                                this.handleCopyLink(result.url)
                                                                            }}> Copy link <FaRegCopy className="save-icon"/></span>
                                                                        </div>
                                                                    </>
                                                                ))
                                                            }
                                                        </>
                                                }
                                            </div>

                                            <nav className="d-flex justify-content-center">
                                                {
                                                    this.state.loading ? "" :
                                                        <ul className="pagination">
                                                            <li className={this.state.offset === "0" || this.state.offset === 0  ? "page-item active" : "page-item"}>
                                                                <a className="page-link"
                                                                   onClick={() => this.handleNext("page", 0)}>1</a>
                                                            </li>
                                                            {
                                                                this.state.itemscount > 40 ?
                                                                    <li className={this.state.offset === 20 ? "page-item active" : "page-item"}>
                                                                        <a className="page-link"
                                                                           onClick={() => this.handleNext("page", 20)}>2</a>
                                                                    </li>
                                                                    : ""
                                                            }
                                                            {
                                                                this.state.itemscount > 60 ?
                                                                    <li className={this.state.offset === 40 ? "page-item active" : "page-item"}>
                                                                        <a className="page-link"
                                                                           onClick={() => this.handleNext("page", 40)}>3</a>
                                                                    </li> : ""
                                                            }
                                                            {
                                                                this.state.itemscount > 80 ?
                                                                    <li className={this.state.offset === 60 ? "page-item active" : "page-item"}>
                                                                        <a className="page-link"
                                                                           onClick={() => this.handleNext("page", 60)}>4</a>
                                                                    </li> : ""
                                                            }
                                                            {/*<li className={this.state.offset === 80 ? "page-item active" : "page-item"}>
                                                                <a className="page-link"
                                                                   onClick={() => this.handleNext("page", 80)}>5</a>
                                                            </li>*/}
                                                            {/*  <li className="page-item"><a
                                                        className={this.state.offset < 4 ? "page-link" : "d-none"}
                                                        onClick={()=>this.handleNext("positive")}>Next</a></li>*/}
                                                        </ul>
                                                }
                                            </nav>
                                        </div>
                                    </div>
                                </div>

                            </div>
                            <div className={"col-md-2"}>
                                <div
                                    className={this.state.copy ? "alert alert-success position-fixed" : "d-none"}>
                                    <FaBookmark/> Link copied successfully
                                </div>
                                <div
                                    className={this.state.saved ? "alert alert-success position-fixed " : "d-none"}>
                                    <FaBookmark/> Article saved successfully
                                </div>
                                <div
                                    className={this.state.exists ? "alert alert-warning position-fixed" : "d-none"}>
                                    <FaBookmark/> Bookmark already exists
                                </div>
                            </div>
                        </div>
                    </>
                </MediaQuery>
                <MediaQuery maxWidth={1200} className="tablet" minWidth={461}>
                    <>
                        <div className={"row mobile notion w-100"} style={{paddingRight:".2rem"}}>
                            <div className={"col-1 col-md-1"}></div>
                            <div className={"col-10 col-md-10"}>
                                {/*  Search box*/}
                                <div className="search-section p-0 col-12 col-md-12 mb-2 mt-4 ">
                                    <div className="row d-flex align-items-center ">
                                        <div className=" col-12 col-md-12 d-flex justify-content-center">
                                            <form onKeyUp={this.handleSearch} style={{width:"100%"}} autoComplete="off">
                                                <div className="form-input form-input-group border" style={{borderRadius:"100px", paddingLeft:"1rem", paddingRight:"1.5rem"}}>
                                                    <span className="input-group-text input-group-text-notion" id="basic-addon1" style={{padding:"0"}}><img src={searchicon}/></span>
                                                    <input className="form-control search-field-text1 mob" type="text" name="q" onChange={this.handleInput} value={this.state.q}
                                                           style={{width: "100%", border:"none"}} placeholder="Search for our resources"/>

                                                </div>
                                            </form>
                                        </div>
                                    </div>

                                </div>
                                {/*  Breadcrumbs*/}
                                <div className="container notion-breadcrumbs">
                                    <ul>
                                        <li onClick={()=>this.handleBreadCrumbs(2, "Planning Research", "planning-research")} style={{cursor:"pointer"}}>
                                            <span className={this.state.phase===2 ?  "bread-active" : ""} >
                                                   {"0" + 1} -  Planning Research
                                            </span>
                                        </li>
                                        <li onClick={()=>this.handleBreadCrumbs(4, "Conducting Research", "conducting-research")} style={{cursor:"pointer"}}>
                                            <span className={this.state.phase===4 ?  "bread-active" : ""} >
                                                   {"0" + 2} -  Conducting Research
                                            </span>
                                        </li>
                                        <li onClick={()=>this.handleBreadCrumbs(3, "Analyzing Research", "analyzing-research")} style={{cursor:"pointer"}}>
                                            <span className={this.state.phase===3 ?  "bread-active" : ""} >
                                                   {"0" + 3} -  Analyzing Research
                                            </span>
                                        </li>
                                        <li onClick={()=>this.handleBreadCrumbs(1, "Presenting Research", "presenting-research")} style={{cursor:"pointer"}}>
                                            <span className={this.state.phase===1 ?  "bread-active" : ""} >
                                                   {"0" + 4} -  Presenting Research
                                            </span>
                                        </li>
                                    </ul>

                                </div>
                            </div>
                        </div>
                        {/*  Results */}
                        <div className={"row" } style={{marginTop:"1.438rem"}}>
                            <div className={"col-12"}>
                                <div className={"col-md-12"}>
                                    <div className={"row"}>
                                        <div className={"col-12 tags position-relative border-top border-bottom"}>
                                            <div className={"row p-0"}>
                                                <div className={"col-1 col-md-1"}></div>
                                                <div className={"col-10 col-md-10"}>
                                                    <div className={"tags-container "}>
                                                        <ul>
                                                            {
                                                                this.state.loading ?
                                                                    <>
                                                                        {
                                                                            [1, 2, 3, 4, 5, 6, 7].map((n) => <SkeletonSearchResult
                                                                                key={n}/>)
                                                                        }

                                                                    </> : <>
                                                                        {
                                                                            this.state.tags.map((tag)=>(
                                                                                <li style={{cursor:"pointer"}} onClick={()=>this.handleTags(tag.id, tag.name)} className={this.state.tagid===tag.id ? "active-tag" : ""}>{tag.name}</li>
                                                                            ))
                                                                        }
                                                                    </>
                                                            }

                                                        </ul>
                                                    </div>
                                                </div>
                                            </div>

                                        </div>
                                        <div className={"col-12"}>
                                            <div className={" container"}>
                                                {
                                                    this.state.loading ?
                                                        <>
                                                            {
                                                                [1, 2, 3, 4, 5, 6, 7].map((n) => <SkeletonSearchResult
                                                                    key={n}/>)
                                                            }

                                                        </> : <>
                                                            <h1 className={"d-flex align-items-center mt-4"} style={{fontSize:"14px", fontWeight:"600"}}>
                                                                {this.state.tagname ?
                                                                    <>
                                                                        <h1 style={{textTransform:"capitalize"}}> {this.state.tagname}</h1>
                                                                    </>
                                                                    : <>
                                                                        <h1 style={{textTransform:"capitalize"}}>
                                                                            {
                                                                                this.state.phasetitle.replace('-', " ")
                                                                            } </h1>

                                                                    </>
                                                                }
                                                            </h1>
                                                            {
                                                                this.state.articles.map((result)=>(
                                                                    <>
                                                                        <div className={"result-section"} style={{marginBottom: "1.875rem", marginTop:".875rem"}}>
                                                                            <a onClick={()=>this.handlePreview(result)}target={"_blank"} style={{textDecorationColor:"#522FFF"}}><h1
                                                                                className="result-url mt-2 mb-2">{result.url.substr(0, 50) + "..."}</h1>
                                                                            </a>
                                                                            <a onClick={()=>this.handlePreview(result)} target={"_blank"} style={{textDecoration:"none"}}>
                                                                                <h1
                                                                                    className="result-name mt-2 mb-2">
                                                                                    <ContentEditable
                                                                                        innerRef={result.title}
                                                                                        html={result.title.substr(0, 50).replace("[", " ").toLowerCase().replace(this.state.searcht.toLowerCase(), "<b style='font-weight: 900'>" + this.state.searcht.toLowerCase() + "</b>") + "..." } // innerHTML of the editable div
                                                                                        disabled={true}       // use true to disable editing
                                                                                        onChange={this.handleChange} // handle innerHTML change
                                                                                        tagName='article' // Use a custom HTML tag (uses a div by default)
                                                                                    />

                                                                                </h1>
                                                                            </a>
                                                                            <p className="result-snippet">
                                                                                <ContentEditable
                                                                                    innerRef={result.content + "..."}
                                                                                    html={result.content.substr(0, 150).replace("[", " ").replace(this.state.searcht.toLowerCase(), "<b style='font-weight: 900'>" + this.state.searcht.toLowerCase() + "</b>")  + "..." ||
                                                                                        <Skeleton/>} // innerHTML of the editable div
                                                                                    disabled={true}       // use true to disable editing
                                                                                    onChange={this.handleChange} // handle innerHTML change
                                                                                    tagName='article' // Use a custom HTML tag (uses a div by default)
                                                                                />

                                                                            </p>
                                                                            {
                                                                                this.state.token ? <span className="save save-icon-onboard"
                                                                                                         onClick={() => this.handleSaveSearch(result, "Notion Tags")}> Save <FaRegBookmark
                                                                                        className="save-icon"/></span> :
                                                                                    <span className="save save-icon-onboard"
                                                                                          onClick={() => this.handleModalOpen(result, "1")}> Save <FaRegBookmark/> </span>
                                                                            }

                                                                            <span className="save" onMouseDown={() => {
                                                                                this.handleCopyLink(result.url)
                                                                            }}> Copy link <FaRegCopy className="save-icon"/></span>
                                                                        </div>
                                                                    </>
                                                                ))
                                                            }
                                                        </>
                                                }
                                            </div>

                                            <nav className="d-flex justify-content-center">
                                                {
                                                    this.state.loading ? "" :
                                                        <ul className="pagination">
                                                            <li className={this.state.offset === "0" || this.state.offset === 0  ? "page-item active" : "page-item"}>
                                                                <a className="page-link"
                                                                   onClick={() => this.handleNext("page", 0)}>1</a>
                                                            </li>
                                                            {
                                                                this.state.itemscount > 40 ?
                                                                    <li className={this.state.offset === 20 ? "page-item active" : "page-item"}>
                                                                        <a className="page-link"
                                                                           onClick={() => this.handleNext("page", 20)}>2</a>
                                                                    </li>
                                                                    : ""
                                                            }
                                                            {
                                                                this.state.itemscount > 60 ?
                                                                    <li className={this.state.offset === 40 ? "page-item active" : "page-item"}>
                                                                        <a className="page-link"
                                                                           onClick={() => this.handleNext("page", 40)}>3</a>
                                                                    </li> : ""
                                                            }
                                                            {
                                                                this.state.itemscount > 80 ?
                                                                    <li className={this.state.offset === 60 ? "page-item active" : "page-item"}>
                                                                        <a className="page-link"
                                                                           onClick={() => this.handleNext("page", 60)}>4</a>
                                                                    </li> : ""
                                                            }
                                                            {/*<li className={this.state.offset === 80 ? "page-item active" : "page-item"}>
                                                                <a className="page-link"
                                                                   onClick={() => this.handleNext("page", 80)}>5</a>
                                                            </li>*/}
                                                            {/*  <li className="page-item"><a
                                                        className={this.state.offset < 4 ? "page-link" : "d-none"}
                                                        onClick={()=>this.handleNext("positive")}>Next</a></li>*/}
                                                        </ul>
                                                }
                                            </nav>
                                        </div>
                                    </div>
                                </div>

                            </div>
                            <div className={"col-md-2"}>
                                <div
                                    className={this.state.copy ? "alert alert-success position-fixed" : "d-none"}>
                                    <FaBookmark/> Link copied successfully
                                </div>
                                <div
                                    className={this.state.saved ? "alert alert-success position-fixed " : "d-none"}>
                                    <FaBookmark/> Article saved successfully
                                </div>
                                <div
                                    className={this.state.exists ? "alert alert-warning position-fixed" : "d-none"}>
                                    <FaBookmark/> Bookmark already exists
                                </div>
                            </div>
                        </div>
                    </>
                </MediaQuery>
                <Footer />
            </>
        );
    }
}
export default MainNotion