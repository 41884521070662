import React, {Component} from "react";
import MediaQuery from "react-responsive";
import searchicon from "../../../assets/Frame 39.png";
import ResourcesNav from "./ResourcesNav";
import {MdGridView,MdReorder} from "react-icons/md";
import SkeletonSearchResult from "../Landing/Skeletons/SkeletonSearchResult";

import SkeletonImage from "../Landing/Skeletons/SkeletonImage";
import axios from "axios";
import buildAPIUrl from "../../../Services/UrlBuilder";
import Header from "../Landing/Header";
import MetaTags from "react-meta-tags";
import Footer from "../Landing/Footer";
import PopularBanner from "./PopularBanner";
import cards from "../../../assets/Group 23.png";
import NotFound from "./NotFound";
import {EventsHandler} from "../Landing/MainFile/MainFunctions";
import SeoMetaData from "./SeoMetaData";

class Books extends Component{
    state={
        items:[],
        loading:true,
        listview:false,
        showfilter:false,
        plantype: 0,
        filter:"",
        popular:[],
        seocontent:""
    }
    handleFilterShow = () => {
        if(this.state.showfilter) {
            this.setState({
                showfilter:false
            })
        } else {
            this.setState({
                showfilter:true
            })
        }
    }
    componentDidMount() {
        if(!localStorage.getItem('token')){
            window.location.assign("/")
        }
        if (window.location.hash.includes("#")){
            this.setState({
                filter:window.location.hash.replace("#", "").replaceAll("%20", " ")
            })
        } else {
            window.scrollTo(0,0)
        }
        EventsHandler("Books Section Opened",  document.URL)

        //Get books list
        axios.get(buildAPIUrl('v1/ux_books/')).then((res)=>{
            this.setState({
                items:res.data,
                loading:false
            })
        }).catch((error)=>{

        })
        //Check user subscription plan. If the response is empty, then the user is on freemium. If there is a response, then the user is on an Individual plan
        const plansresponse = axios.get(buildAPIUrl('v1/subscriptions/'), {
            headers:{
                Authorization: `Bearer ${localStorage.getItem('token')}`
            }
        }).then((res)=>{
            if(res.data.length > 0){
                this.setState({
                    plantype:1
                })
            }
        }).catch((error)=>{

        })
        //Featured Item
         axios.get(buildAPIUrl(`v1/featured_post/?category_id=4`)).then((res)=>{
            this.setState({
                popular:res.data
            })
        }).catch((error)=>{

        })

        //Increase views by 1
        axios.get(buildAPIUrl('v1/collections/5/')).then(()=>{

        }).catch((error)=>{

        })
    }
    //Change from gridview to list view or vice versa
    handleChangeView = () =>{
        if(this.state.listview){
            this.setState({
                listview:false
            })
        } else {
            this.setState({
                listview:true
            })
        }
    }
    handleSearch = async (e) => {
        await this.setState({
            filter:e.target.value
        })
    }
    saveEvent = (id, url)=> {
            EventsHandler("Books Link Clicked",  document.URL,"none", id)
        //Increase views by 1
        axios.get(buildAPIUrl(`v1/ux_books/${id}/`)).then(()=>{
            window.open(url)
        }).catch((error)=>{

        })

    }
    render() {

            const filtereditems = this.state.items.filter((result)=> {
                if (this.state.filter === "") {
                    return result
                } else if (result.name.toLowerCase().includes(this.state.filter.toLowerCase())) {
                    return result
                }
            })


        return (
            <>
                <SeoMetaData title={"Books"} />
                <Header pageType={"Free"} />
                <MediaQuery minWidth={1200}>
                    <div className={"row "} style={{paddingLeft: "1rem", paddingRight:"1rem", marginTop:"7rem"}}>
                        <div className={"col-md-2 side-filters"}></div>
                        <div className={"col-md-8 col-12 px-4"}>
                            <div className="">
                                <div className="search-section col-12 col-md-12 mt-4 ">
                                    <div className="row d-flex align-items-center ">
                                        <div className=" col-12 col-md-12 d-flex justify-content-center">
                                            <form onKeyUp={this.handleSearch} style={{width:"100%"}} autoComplete="off">
                                                <div className="form-input input-group border" style={{borderRadius:"100px", paddingLeft:"1rem", paddingRight:"1.5rem"}}>
                                                             <span className="input-group-text border-0" id="basic-addon1" style={{padding:"0"}}>
                                                <img src={searchicon}/>
                                            </span>
                                                    <input className="search-field-text1 form-control mob border-0" type="text" name="q" onChange={this.handleInput} value={this.state.q}
                                                           style={{width: "90%", paddingLeft:"1rem", borderRadius:"6px"}} placeholder="Search for books"/>

                                                </div>
                                            </form>
                                        </div>
                                    </div>

                                </div>
                                <div className={"saved-sources"}>
                                    <div className="row">
                                        <ResourcesNav />
                                    </div>
                                    <div className={"row p-0" }>
                                        <div className="col-md-8 col-6 d-flex align-items-center" style={{marginTop:"1rem"}}>
                                            <h1 style={{fontSize:"20px"}}>Learn from the Best: Our Handpicked List of UX Books for 2023</h1>
                                        </div>
                                    </div>
                                    <PopularBanner columns={"2"} name={"Books"} />
                                    <div className={"originals-navigation"}>
                                        <div className={"row"}>
                                            <div className={"col-md-6 "}>
                                                <div onClick={this.handleChangeView} style={{cursor:"pointer"}}>
                                                    {
                                                        this.state.listview ?  <span >
                                                <MdGridView style={{border:"2px solid #200E32", fontSize:"25px", color:"#A69FAD", borderRadius:"3px"}}/>
                                            </span> :  <span >
                                                <MdGridView style={{border:"2px solid #200E32", fontSize:"25px", color:"#A69FAD", borderRadius:"3px", background:"black"}}/>
                                            </span>
                                                    }
                                                    {
                                                        this.state.listview ?
                                                            <span>
                                                 <MdReorder style={{
                                                     marginLeft: "1rem",
                                                     color: "white",
                                                     background: "black",
                                                     border: "1.5px solid #200E32",
                                                     fontSize: "25px",
                                                     borderRadius: "3px"
                                                 }}/>
                                           </span> : <span>
                                                 <MdReorder style={{
                                                     marginLeft: "1rem",
                                                     color: "#A69FAD",
                                                     background: "white",
                                                     border: "1.5px solid #200E32",
                                                     fontSize: "25px",
                                                     borderRadius: "3px"
                                                 }}/>
                                           </span>
                                                    }
                                                </div>
                                            </div>
                                            <div className={"col-md-6 d-flex justify-content-end"}>

                                            </div>

                                        </div>
                                    </div>
                                    {this.state.listview ?
                                        //List view
                                        <div>
                                            {
                                                this.state.loading ? <>
                                                        <div className="row">

                                                            {
                                                                [1, 2, 3, 4].map((n) => (
                                                                    <div className="col-md-4">
                                                                        <SkeletonSearchResult key={n}/>
                                                                    </div>
                                                                ) )
                                                            }

                                                        </div>

                                                    </> :

                                                    <div className="p-0 mt-5">

                                                        {
                                                            filtereditems.length > 0 ?
                                                                <>
                                                                    <div className={"row"}>
                                                                        {
                                                                            filtereditems.map((item)=>(
                                                                                <>
                                                                                    <div className={"col-md-6"}>
                                                                                        <div className={"row px-3"} >
                                                                                            <div className="col-md-12 originals-list " style={{ borderTop:"0.5px solid rgba(235, 235, 235, 0.9)", paddingTop:"15px", marginTop:"15px"}}>
                                                                                                <a onClick={()=>this.saveEvent(item.name, item.url)} href={item.url} target={"_blank"} style={{textDecoration:"none"}}>
                                                                                                    <div className="card-body  p-0">
                                                                                                        <div className={"row originals-list-view-items d-flex align-items-center"}>
                                                                                                            <div className={"col-md-4"}>
                                                                                                                <img src={item.image} style={{ width:"100%", borderRadius:"5px"}}/>
                                                                                                            </div>
                                                                                                            <div className={"col-md-8"}>
                                                                                                                <h6 style={{color:"#070707", fontSize:"20px", textDecoration:"underline"}}>
                                                                                                                    {item.name.substr(0, 25)}
                                                                                                                </h6>
                                                                                                                <h6 style={{color:"#070707", fontSize:"12px", fontWeight:"400", lineHeight:"15px"}}>
                                                                                                                    {item.author}
                                                                                                                </h6>
                                                                                                                {/* <h6 style={{color:"#00944D", fontSize:"10px", fontWeight:"400", lineHeight:"15px"}}>
                                                                                                                Listen on Spotify <MdOpenInNew />
                                                                                                            </h6>*/}
                                                                                                            </div>
                                                                                                        </div>
                                                                                                    </div>
                                                                                                </a>
                                                                                            </div>
                                                                                            <div className={"col-md-4"}></div>
                                                                                            <div className={"col-md-2 d-flex align-items-center"}>

                                                                                            </div>
                                                                                        </div>
                                                                                    </div>

                                                                                </>

                                                                            ))
                                                                        }

                                                                    </div>


                                                                </>

                                                                : <div className={"container d-flex justify-content-center"}>
                                                                    <div className="search-message">
                                                                        <img src={cards}/><br/>
                                                                        <h6>No results found for this keyword</h6>
                                                                        <p>This word is not available in books list, try our search
                                                                            engine instead</p>
                                                                        <NotFound term={this.state.filter} />
                                                                    </div>
                                                                </div>
                                                        }

                                                    </div>
                                            }

                                        </div> :

                                        //Grid view
                                        <div>
                                            {
                                                this.state.loading ? <>
                                                    <div className="row">

                                                        {
                                                            [1, 2, 3, 4].map((n) => (
                                                                <div className="col-md-3">
                                                                    <SkeletonImage key={n}/>
                                                                </div>
                                                            ) )
                                                        }

                                                    </div>

                                                </> :  <div className="row">
                                                    {
                                                        filtereditems.length > 0  ?
                                                            <>
                                                                {
                                                                    filtereditems.map((item)=>(
                                                                        <>

                                                                            <div className="col-md-3 " id={item.id}>
                                                                                <div className={"card border originals-card mt-5"}>
                                                                                    <a onClick={()=>this.saveEvent(item.name, item.url)} href={item.url} target={"_blank"} style={{textDecoration:"none"}}>
                                                                                        <div className="card-body h-100 p-0">
                                                                                            <img src={item.image} style={{width:"100%", borderRadius:"4px"}}/>
                                                                                        </div>
                                                                                        <div className={"card-footer border-0 bg-white p-0"}>
                                                                                            <div className="">
                                                                                                <h6 className={"p-0"} style={{fontSize:"16px", marginTop:"13px", marginLeft:"0"}}>
                                                                                                    {item.name.substr(0, 25)}
                                                                                                </h6>
                                                                                                <h6 className={"p-0 m-0"} style={{fontSize:"10px"}}>
                                                                                                    {item.author}
                                                                                                </h6>
                                                                                            </div>
                                                                                        </div>
                                                                                    </a>
                                                                                </div>

                                                                            </div>

                                                                        </>

                                                                    ))
                                                                }

                                                            </>

                                                            : <div className={"container d-flex justify-content-center"}>
                                                                <div className="search-message">
                                                                    <img src={cards}/><br/>
                                                                    <h6>No results found for this keyword</h6>
                                                                    <p>This word is not available in books list, try our search
                                                                        engine instead</p>
                                                                    <NotFound term={this.state.filter} />
                                                                </div>
                                                            </div>
                                                    }

                                                </div>
                                            }

                                        </div>
                                    }
                                </div>



                            </div>
                        </div>
                    </div>

                </MediaQuery>
                <MediaQuery maxWidth={460}>
                    <div className={"row"}>
                        <div className={"col-md-8"}>
                            <div className="container-fluid mobile p-0">
                                <div className="search-section col-12 col-md-12 mb-2 mt-4 ">
                                    <div className="row d-flex align-items-center ">
                                        <div className=" col-12 col-md-12 d-flex justify-content-center">
                                            <form onKeyUp={this.handleSearch} style={{width:"100%"}} autoComplete="off">
                                                <div className="form-input form-input-group border" style={{borderRadius:"100px", paddingLeft:"1rem", paddingRight:"1.5rem"}}>
                                                    <span className="input-group-text input-group-text-notion" id="basic-addon1" style={{padding:"0"}}><img src={searchicon}/></span>
                                                    <input className="form-control search-field-text1 mob" type="text" name="q" onChange={this.handleInput} value={this.state.q}
                                                           style={{width: "100%", border:"none"}} placeholder="Search for books"/>

                                                </div>
                                            </form>
                                        </div>
                                    </div>

                                </div>
                                    <ResourcesNav />
                                <div className="col-md-8 col-8" style={{paddingLeft:"1rem", marginTop:"1.5rem"}}>
                                    <h1 style={{fontSize:"14px",  fontWeight:"600"}}>Learn from the Best: Our Handpicked List of UX Books for 2023</h1>
                                </div>

                                <PopularBanner name={"Books"} />

                                    <div>
                                        {
                                            this.state.loading ? <>
                                                    <div className="row">

                                                        {
                                                            [1, 2, 3, 4].map((n) => (
                                                                <div className="col-md-4">
                                                                    <SkeletonSearchResult key={n}/>
                                                                </div>
                                                            ) )
                                                        }

                                                    </div>

                                                </> :

                                                <div className="p-0">

                                                    {
                                                        filtereditems.length > 0 ?
                                                            <>
                                                                <div className={"row p-0"}>
                                                                    {
                                                                        filtereditems.map((item)=>(
                                                                            <>
                                                                                <div className={"col-12"}>
                                                                                    <div className={"row px-3"} >
                                                                                        <div className="col-md-12 originals-list " style={{ borderTop:"0.5px solid rgba(235, 235, 235, 0.9)", paddingTop:"15px", marginTop:"15px"}}>
                                                                                            <a onClick={()=>this.saveEvent(item.name, item.url)} href={item.url} target={"_blank"}  style={{textDecoration:"none"}}>
                                                                                                <div className="card-body  p-0">
                                                                                                    <div className={"row originals-list-view-items d-flex align-items-center"}>
                                                                                                        <div className={"col-5"}>
                                                                                                            <img src={item.image} style={{ width:"100%", borderRadius:"5px"}}/>
                                                                                                        </div>
                                                                                                        <div className={"col-7"}>
                                                                                                            <h6 style={{color:"#070707", fontSize:"20px", textDecoration:"underline"}}>
                                                                                                                {item.name.substr(0, 25)}
                                                                                                            </h6>
                                                                                                            <h6 style={{color:"#070707", fontSize:"12px", fontWeight:"400", lineHeight:"15px"}}>
                                                                                                                {item.author}
                                                                                                            </h6>
                                                                                                        </div>
                                                                                                    </div>
                                                                                                </div>
                                                                                            </a>
                                                                                        </div>
                                                                                        <div className={"col-md-4"}></div>
                                                                                        <div className={"col-md-2 d-flex align-items-center"}>

                                                                                        </div>
                                                                                    </div>
                                                                                </div>

                                                                            </>

                                                                        ))
                                                                    }
                                                                </div>


                                                            </>

                                                            : <>
                                                                <div className={"container d-flex justify-content-center"}>
                                                                    <div className="search-message">
                                                                        <img src={cards}/><br/>
                                                                        <h6>No results found for this keyword</h6>
                                                                        <p>This word is not available in books list, try our search
                                                                            engine instead</p>
                                                                        <NotFound term={this.state.filter} />
                                                                    </div>
                                                                </div>
                                                            </>
                                                    }

                                                </div>
                                        }

                                    </div>

                            </div>
                        </div>
                    </div>
                </MediaQuery>
                <MediaQuery maxWidth={1200} minWidth={581}>
                    <div className={"row"}>
                        <div className={"col-md-8"}>
                            <div className="container-fluid mobile">
                                <div className="search-section col-12 col-md-12 mb-2 mt-4 ">
                                    <div className="row d-flex align-items-center ">
                                        <div className=" col-12 col-md-12 d-flex justify-content-center">
                                            <form onKeyUp={this.handleSearch} style={{width:"100%"}} autoComplete="off">
                                                <div className="form-input form-input-group border" style={{borderRadius:"100px", paddingLeft:"1rem", paddingRight:"1.5rem"}}>
                                                    <span className="input-group-text input-group-text-notion" id="basic-addon1" style={{padding:"0"}}><img src={searchicon}/></span>
                                                    <input className="form-control search-field-text1 mob" type="text" name="q" onChange={this.handleInput} value={this.state.q}
                                                           style={{width: "100%", border:"none"}} placeholder="Search for books"/>

                                                </div>
                                            </form>
                                        </div>
                                    </div>

                                </div>
                                <div className="row">
                                    <ResourcesNav />
                                    <div className={"originals-navigation mt-2"}>
                                        <div className={"row"}>
                                            <div className={"col-md-6 col-6 "}>
                                                <h1 style={{fontSize:"20px"}}>Learn from the Best: Our Handpicked List of UX Books for 2023</h1>
                                            </div>

                                            <div className={"col-md-6 col-6 d-flex justify-content-end "}>
                                                <div onClick={this.handleChangeView} style={{cursor:"pointer"}}>
                                                    {
                                                        this.state.listview ?  <span >
                                                <MdGridView style={{border:"2px solid #200E32", fontSize:"25px", color:"#A69FAD", borderRadius:"3px"}}/>
                                            </span> :  <span >
                                                <MdGridView style={{border:"2px solid #200E32", fontSize:"25px", color:"#A69FAD", borderRadius:"3px", background:"black"}}/>
                                            </span>
                                                    }
                                                    {
                                                        this.state.listview ?
                                                            <span>
                                                 <MdReorder style={{
                                                     marginLeft: "1rem",
                                                     color: "white",
                                                     background: "black",
                                                     border: "1.5px solid #200E32",
                                                     fontSize: "25px",
                                                     borderRadius: "3px"
                                                 }}/>
                                           </span> : <span>
                                                 <MdReorder style={{
                                                     marginLeft: "1rem",
                                                     color: "#A69FAD",
                                                     background: "white",
                                                     border: "1.5px solid #200E32",
                                                     fontSize: "25px",
                                                     borderRadius: "3px"
                                                 }}/>
                                           </span>
                                                    }
                                                </div>
                                            </div>

                                        </div>
                                    </div>
                                </div>
                                <PopularBanner name={"Books"} />
                                {this.state.listview ?
                                    //List view
                                    <div>
                                        {
                                            this.state.loading ? <>
                                                    <div className="row">

                                                        {
                                                            [1, 2, 3, 4].map((n) => (
                                                                <div className="col-md-4">
                                                                    <SkeletonSearchResult key={n}/>
                                                                </div>
                                                            ) )
                                                        }

                                                    </div>

                                                </> :

                                                <div className="p-0 mt-2">

                                                    {
                                                        filtereditems.length > 0 ?
                                                            <>
                                                                <div className={"row"}>
                                                                    {
                                                                        filtereditems.map((item)=>(
                                                                            <>
                                                                                <div className={"col-12"}>
                                                                                    <div className={"row px-3"} >
                                                                                        <div className="col-md-12 originals-list " style={{ borderTop:"0.5px solid rgba(235, 235, 235, 0.9)", paddingTop:"15px", marginTop:"15px"}}>
                                                                                            <a  onClick={()=>this.saveEvent(item.name, item.url)} target={"_blank"} href={item.url}  style={{textDecoration:"none"}}>
                                                                                                <div className="card-body  p-0">
                                                                                                    <div className={"row originals-list-view-items d-flex align-items-center"}>
                                                                                                        <div className={"col-5"}>
                                                                                                            <img src={item.image} style={{ width:"100%", borderRadius:"5px"}}/>
                                                                                                        </div>
                                                                                                        <div className={"col-7"}>
                                                                                                            <h6 style={{color:"#070707", fontSize:"20px", textDecoration:"underline"}}>
                                                                                                                {item.name.substr(0, 25)}
                                                                                                            </h6>
                                                                                                            <h6 style={{color:"#070707", fontSize:"12px", fontWeight:"400", lineHeight:"15px"}}>
                                                                                                                {item.author}
                                                                                                            </h6>
                                                                                                            {/*<h6 style={{color:"#00944D", fontSize:"10px", fontWeight:"400", lineHeight:"15px"}}>
                                                                                                                Listen on Spotify <MdOpenInNew />
                                                                                                            </h6>*/}
                                                                                                        </div>
                                                                                                    </div>
                                                                                                </div>
                                                                                            </a>
                                                                                        </div>
                                                                                        <div className={"col-md-4"}></div>
                                                                                        <div className={"col-md-2 d-flex align-items-center"}>

                                                                                        </div>
                                                                                    </div>
                                                                                </div>

                                                                            </>

                                                                        ))
                                                                    }
                                                                </div>


                                                            </>

                                                            : <div className={"container d-flex justify-content-center"}>
                                                                <div className="search-message">
                                                                    <img src={cards}/><br/>
                                                                    <h6>No results found for this keyword</h6>
                                                                    <p>This word is not available in books list, try our search
                                                                        engine instead</p>
                                                                    <NotFound term={this.state.filter} />
                                                                </div>
                                                            </div>
                                                    }

                                                </div>
                                        }

                                    </div> :

                                    //Grid view
                                    <div>
                                        {
                                            this.state.loading ? <>
                                                <div className="row mt-5">

                                                    {
                                                        [1, 2, 3, 4].map((n) => (
                                                            <div className="col-md-3">
                                                                <SkeletonImage key={n}/>
                                                            </div>
                                                        ) )
                                                    }

                                                </div>

                                            </> :  <div className="row p-0">
                                                {
                                                    filtereditems.length > 0 ?
                                                        <>
                                                            {
                                                                filtereditems.map((item)=>(
                                                                    <>

                                                                        <div className="col-md-3 col-6 ">
                                                                            <div className={"card border originals-card mt-3"}>
                                                                                <a onClick={()=>this.saveEvent(item.name, item.url)} target={"_blank"} href={item.url} style={{textDecoration:"none"}}>
                                                                                    <div className="card-body h-100 p-0">
                                                                                        <img src={item.image} style={{width:"100%", borderRadius:"4px"}}/>
                                                                                    </div>
                                                                                    <div className={"card-footer border-0 bg-white p-0"}>
                                                                                        <div className="">
                                                                                            <h6 className={"p-0"} style={{fontSize:"16px", marginTop:"13px"}}>
                                                                                                {item.name.substr(0, 25)}
                                                                                            </h6>
                                                                                            <h6 className={"p-0 m-0"} style={{fontSize:"10px"}}>
                                                                                                {item.author}
                                                                                            </h6>
                                                                                        </div>
                                                                                    </div>
                                                                                </a>
                                                                            </div>

                                                                        </div>

                                                                    </>

                                                                ))
                                                            }

                                                        </>

                                                        : <div className={"container d-flex justify-content-center"}>
                                                            <div className="search-message">
                                                                <img src={cards}/><br/>
                                                                <h6>No results found for this keyword</h6>
                                                                <p>This word is not available in books list, try our search
                                                                    engine instead</p>
                                                                <NotFound term={this.state.filter} />
                                                            </div>
                                                        </div>
                                                }

                                            </div>
                                        }

                                    </div>
                                }


                            </div>
                        </div>
                    </div>
                </MediaQuery>
                <Footer />
            </>

        );
    }
}
export default Books