import React, {Component} from "react";
import mixpanel from 'mixpanel-browser';
import {EventsHandler, getSuggestions, handleSearch} from "./MainFunctions";
import Tags from "../Tags/Tags";
import ResourcesSection from "../ResourcesSection";
import SearchArea from "./SearchArea";
import Footer from "../Footer";
import laptopimg from "../../../../assets/handshake.png";
import bannerimg from "../../../../assets/Webinar 2023.jpeg"
import Partners from "./Partners";
import axios from "axios";
import buildAPIUrl from "../../../../Services/UrlBuilder";
import swal from "sweetalert";
import {GoogleLogin} from "react-google-login";
import ReactPlayer from "react-player";
import Header from "../Header";
import "./Main.css"
import {Link} from "react-router-dom";
import UpdateProfileModal from "../../Modals/UpdateProfileModal";
import UpgradeModal from "../../Modals/UpgradeModal";
import SeoMetaData from "../../RBResourceTables/SeoMetaData";
import {
    MdArrowForward,
    MdFormatQuote,
    MdOutlineRemoveRedEye,
    MdOutlineShoppingBag,
    MdShoppingBag
} from "react-icons/md";
import {FaQuoteLeft} from "react-icons/fa";
import conducting from "../../../../assets/conducting.svg";
import analyzing from "../../../../assets/analyzing.svg";
import presenting from "../../../../assets/presenting.svg";
import planning from "../../../../assets/planning.svg";
import logo from "../../../../assets/RB LOGO1.png";


class NotLoggedInMainTablet extends Component{
    state = {
        image: localStorage.getItem('image'),
        clientID:process.env.REACT_APP_GOOGLE_CLIENT_ID,
        token: localStorage.getItem('token'),
        q: "",
        active: false,
        name:localStorage.getItem('username'),
        suggestion:[],
        tags:[],
        stepsEnabled:true,
        initialStep:0,
        isTourOpen: false,
        modalshow:false,
        showloginmodal:false,
        partners:[],
        highlight:[],
        highlight1:[],
        company:"",
        role:"",
        showupdateprofile:false,
        saveprofilesuccess:false,
        roles:[],
        collections:[],
        starting:"",
        presenting:"",
        analyzing:"",
        conducting:"",
        communitycollections:[],
    }

    handleModalInput=(e)=>{
        this.setState({
            [e.target.name]: e.target.value
        })
    }
    handleSaveUserDetails = () => {
        axios.patch(buildAPIUrl('v1/users/me/'), {
            company:this.state.company,
            rb_role:this.state.role
        }, {
            headers:{
                Authorization: `Bearer ${localStorage.getItem('token')}`
            }
        }).then((res)=>{
            localStorage.setItem('profileupdate', "1")
            this.setState({
                saveprofilesuccess:true
            })
            setTimeout(()=>{
                this.setState({
                    showupdateprofile:false,
                })
            }, 3000)
        }).catch((error)=>{

        })
    }
    handleInput = (e) => {
        getSuggestions(e,this.state.token, (res)=>{
            this.setState({
                suggestion:res.data.suggestionGroups[0].searchSuggestions
            })
        }, (error)=>{
        })
        this.setState({
            [e.target.name]: e.target.value
        })
    }
    handleSearch = async (e) =>{
        e.preventDefault();
        handleSearch(this.state.q)
    }
    openLink1 = (query) =>{
        localStorage.setItem('searchquery', query)
        window.location.assign(`/all-results`)
    }
    handleModalClose = ()=>{
        this.setState({
            showupdateprofile:false,
        })
    }
    componentDidMount() {
        mixpanel.init('7726c3c95fe5b5b25cb8084d85b4de94')
        mixpanel.track('Loaded landing page')
        if(localStorage.getItem('token')) {
            mixpanel.identify(localStorage.getItem('email'))
            mixpanel.register(localStorage.getItem('email'))
            mixpanel.people.set({ "$name": localStorage.getItem('username'), "$email":localStorage.getItem('email') });
        }
        axios.get(buildAPIUrl('v1/weekly_highlights/')).then((res)=>{
            this.setState({
                highlight:res.data[0]
            })
            this.setState({
                highlight1:res.data[1]
            })
        }).catch((error)=>{

        })

        //Show the update profile modal
        if(!localStorage.getItem('token') || !localStorage.getItem('profileupdate') ){
            this.setState({
                showupdateprofile:true
            })
        }
        axios.get(buildAPIUrl('v1/users/role/'), {
            headers:{
                Authorization: `Bearer ${localStorage.getItem('token')}`
            }
        }).then((res)=>{
            this.setState({
                roles:res.data
            })
        })
        axios.get(buildAPIUrl('v1/collections/'))
            .then((res) => {

                this.setState({
                    collections: res.data
                })
            }).catch((error) => {
        })
        axios.get(buildAPIUrl('v1/article_phases/')).then((res) => {
            this.setState({
                links: res.data,
                starting: res.data[1],
                presenting: res.data[0],
                analyzing: res.data[2],
                conducting: res.data[3],
            })
        })
        axios.get(buildAPIUrl('v1/collections/group/'))
            .then((res)=>{
                console.log(res.data, "Special Collections")
                this.setState({
                    communitycollections:res.data
                })
            }).catch((error)=>{
        })
    }


    render() {

        const onSuccess = async (res) => {
            var myHeaders = new Headers();
            myHeaders.append("Content-Type", "application/x-www-form-urlencoded");
            var requestOptions = {
                method: 'POST',
                headers: myHeaders,
                redirect: 'follow'
            };
            localStorage.setItem('token', res.accessToken)
            localStorage.setItem('username', res.profileObj.givenName)
            localStorage.setItem('image', res.profileObj.imageUrl)
            localStorage.setItem('email', res.profileObj.email)
            const resp = await axios.post(buildAPIUrl('v1/social_auth/convert-token'), {
                token: localStorage.getItem('token'),
                client_id: process.env.REACT_APP_SOCIAL_AUTH_CLIENT_ID,
                client_secret:process.env.REACT_APP_SOCIAL_AUTH_CLIENT_SECRET,
                grant_type:"convert_token",
                backend: "google-oauth2"
            }, requestOptions).then((response)=>{
                localStorage.setItem('token', response.data.access_token)
                localStorage.setItem('refresh_token', response.data.refresh_token)
                EventsHandler(`Logged in using Google`, document.URL)
                axios.get(buildAPIUrl('v1/users/me/'), {
                    headers:{
                        Authorization: `Bearer ${response.data.access_token}`
                    }
                }).then((res)=>{
                    if (res.data.company){
                        localStorage.setItem('profileupdate', "1")
                    }
                    window.location.assign("/")
                })
            }).catch((err)=>{
                swal({
                    title: "Opps!",
                    text: "The selected email already exists",
                    icon: "error",
                    button: "Close",
                }).then((resp)=>{
                    window.location.assign("/")
                    localStorage.clear()
                });
            })
        }
        const onFailure = (res) => {

        }
        return (
            <>
                <div className={"tablet"}>
                    <SeoMetaData title={"Home"} />
                    {
                        localStorage.getItem('token') ? <>
                            <Header type={"loggedin"} />
                            <UpdateProfileModal device={"desktop"} />
                            <UpgradeModal />
                            <div className="container-fluid p-0">
                                <SearchArea type={"desktop"} />
                            </div>
                            <div className={"container"}>
                                <div className={"row d-flex justify-content-center"}>
                                    <div className={"col-md-12"}>
                                        <div className={"highlight"}>
                                            <div className={"row"}>
                                                <div className={"col-md-12"}>
                                                    <h5>Check out new learning opportunities</h5>
                                                    <div className={"quote"}>
                                                        <FaQuoteLeft className={"icon-q"}/>
                                                        <p>
                                                            Weekly Insights: Elevate Your UX Game.
                                                            Fresh strategies, resources and expert tips to transform your UX skills every week.
                                                        </p>
                                                    </div>
                                                </div>
                                                <div className={"col-md-6 mt-3"}>
                                                    {this.state.highlight ?
                                                        <>
                                                            <a target={"_blank"} style={{width: "100%"}} href={this.state.highlight.link}>
                                                                <img alt={"home banner image"} width={"373"} height={"300"}
                                                                     src={this.state.highlight.image}
                                                                     style={{width: "100%", height: "auto", borderRadius:"10px"}}/>

                                                            </a>
                                                        </>
                                                        : <></>
                                                    }
                                                </div>
                                                <div className={"col-md-6 mt-3"}>
                                                    {this.state.highlight1 ?
                                                        <>
                                                            <a target={"_blank"} style={{width: "100%"}} href={this.state.highlight1.link}>
                                                                <img alt={"home banner image"} width={"373"} height={"300"}
                                                                     src={this.state.highlight1.image}
                                                                     style={{width: "100%", height: "auto", borderRadius:"10px"}}/>

                                                            </a>
                                                        </>
                                                        : <></>
                                                    }
                                                </div>
                                            </div>
                                        </div>
                                        <div className="categories ">
                                            <h6>Explore Our Collections</h6>
                                            <div className="row h-100" style={{marginTop:"0.875rem"}}>
                                                {
                                                    this.state.collections.map((collection)=>(
                                                        <>
                                                            <div className="col-md-4" style={{marginTop:"1.625rem"}}>
                                                                <a href={collection.url} style={{textDecoration:"none"}}>
                                                                    <div className={"container h-100 pb-3 "}>
                                                                        <div className="categories-content-main h-100 d-flex align-items-end flex-column">
                                                                            <div className={"container-fluid p-0"}>
                                                                                <div className={"row pt-3"}>
                                                                                    <div className={"col-md-6"}>
                                                                                        <img src={collection.image ? collection.image : ""} style={{ height:"1.875rem", width:"1.875rem"}}/>
                                                                                    </div>
                                                                                    <div className={"col-md-6 text-end"}>
                                                                                        <span style={{backgroundColor:"white", padding:"0.125rem .5rem", borderRadius:"1.438rem", color:"#000000", fontSize:"10px", height:"1.375rem"}}><span style={{fontSize:"14px"}}><MdOutlineRemoveRedEye /> </span>{collection.number_of_views}</span>
                                                                                    </div>
                                                                                </div>


                                                                            </div>
                                                                            <div className={"container-fluid p-0 mt-auto"} style={{marginTop:"1.25rem", marginBottom:".875rem"}}>
                                                                                <div className={"row"}>
                                                                                    <div className={"col-md-9"}>
                                                                                        <h6 style={{marginTop:"30px"}}>
                                                                                            {collection.header}
                                                                                        </h6>
                                                                                        <p  style={{fontSize:"11px", fontWeight:"400", margin:"0", lineHeight:"150%" }}>{collection.description}</p>

                                                                                    </div>
                                                                                    <div className={"col-md-3 d-flex align-items-end justify-content-end p-0"}>
                                                                                        <div style={{height:"38.6px", width:"38.6px", borderRadius:"50%", background:"white"}} className={"d-flex justify-content-center align-items-center"}>
                                                                                            <a href={collection.url} style={{fontSize:"19.3px"}}><MdArrowForward /></a>
                                                                                        </div>

                                                                                    </div>
                                                                                </div>
                                                                            </div>


                                                                        </div>

                                                                    </div>
                                                                </a>
                                                            </div>
                                                        </>

                                                    ))
                                                }

                                            </div>
                                        </div>

                                    </div>

                                </div>


                            </div>
                            <div className={"container"}>
                                <div className={"row d-flex justify-content-center"}>
                                    <div className={"col-md-12"}>
                                        <div className={"stages"}>
                                            <div className={"row d-flex justify-content-center"}>
                                                <div className={"col-md-11"}>
                                                    <h6>Stages of UX Research</h6>
                                                    <div className={"row"}>
                                                        {
                                                            this.state.starting ? <>
                                                                <div className="col-md-6 mt-3 stages-items">
                                                                    <Link to={"/articles/4/planning-research"} style={{textDecoration:"none"}}>
                                                                        <div className={"container "}>
                                                                            <img src={planning} />
                                                                            <h1 className={"m-0"} >
                                                                                Planning Research
                                                                            </h1>

                                                                        </div>
                                                                    </Link>
                                                                </div>
                                                            </> : <>

                                                            </>
                                                        }
                                                        {
                                                            this.state.conducting ? <>
                                                                <div className="col-md-6 mt-3 stages-items">
                                                                    <Link to={"/articles/4/conducting-research"} style={{textDecoration:"none"}}>
                                                                        <div className={"container"}>
                                                                            <img src={conducting}/>
                                                                            <h1 className={"m-0"}>
                                                                                Conducting Research
                                                                            </h1>
                                                                        </div>
                                                                    </Link>
                                                                </div>
                                                            </> : <>

                                                            </>
                                                        }

                                                        {
                                                            this.state.analyzing ? <>
                                                                <div className="col-md-6 mt-3 stages-items">
                                                                    <Link to={"/articles/3/analyzing-research"} style={{textDecoration:"none"}}>
                                                                        <div className={"container"}>
                                                                            <img src={analyzing}/>
                                                                            <h1 className={"m-0"}>
                                                                                Analyzing Research
                                                                            </h1>
                                                                        </div>
                                                                    </Link>
                                                                </div>
                                                            </> : <>

                                                            </>
                                                        }
                                                        {
                                                            this.state.presenting ? <>
                                                                <div className="col-md-6 mt-3 stages-items">
                                                                    <Link to={"/articles/1/presenting-research"}
                                                                          style={{textDecoration: "none"}}>
                                                                        <div
                                                                            className={"container"}>
                                                                            <img src={presenting}/>
                                                                            <h1 className={"m-0"}>
                                                                                Presenting Research
                                                                            </h1>
                                                                        </div>
                                                                    </Link>
                                                                </div>
                                                            </> : <>

                                                            </>
                                                        }

                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className={"container mb-5"}>
                                <div className={"row d-flex justify-content-center"}>
                                    <div className={"col-md-12"}>
                                        <div className="special-collections ">
                                            <h6>Special Collections</h6>
                                            {
                                                this.state.communitycollections.length > 0 ? <>
                                                    <div className="row h-100" style={{marginTop:"0.875rem"}}>
                                                        {
                                                            //.filter((result)=>{return result.is_active})
                                                            this.state.communitycollections.map((collection)=>(
                                                                <>
                                                                    <div className="col-md-4 d-flex justify-content-center"  style={{marginTop:"1.625rem", cursor:"pointer" }}>
                                                                        <div className={"container-fluid p-0 community-container bg-white"} style={{ borderRadius:"20px"}}>
                                                                            <Link onClick={()=>this.handleSubCollection(collection.organisation, collection.image, collection.name)}  to={`/special-collections/${collection.id}/${collection.name.replaceAll(" ", "-")}`} style={{textDecoration:"none"}}>
                                                                                <img src={collection.image ? collection.image : ""}/>
                                                                                <div className={"container"}>
                                                                                    <h6  style={{marginTop:"30px"}}>
                                                                                        {collection.name}
                                                                                    </h6>
                                                                                    <button>
                                                                                        Explore
                                                                                    </button>
                                                                                </div>
                                                                            </Link>
                                                                        </div>

                                                                    </div>
                                                                </>
                                                            ))
                                                        }

                                                    </div>
                                                </> : <></>
                                            }
                                        </div>

                                    </div>

                                </div>


                            </div>
                            <Footer/>
                        </> : <>
                            <Header type={"notloggedin"} />
                            <div className={"container-fluid p-0"}>
                                <div className={"row  w-100 "}  style={{minHeight:"92vh"}} >
                                    <div className={"col-md-6 d-flex align-items-center"} style={{background:"#F6F4FF", padding:"2.813rem 0 1.375rem 2.375rem "}}>

                                        <div className={"container-fluid h-100 p-0 d-flex justify-content-center video-container"} style={{ overflowY:"hidden"}}>
                                            <Link to={"/join-rb"} >
                                                <img src="https://res.cloudinary.com/analogteams/image/upload/v1681369291/Group_90147_ds6kzl.svg"/>
                                            </Link>

                                        </div>


                                    </div>
                                    <div className={"col-md-6 h-100"} style={{padding:"2.813rem 2.375rem 0.375rem 2.375rem  "}}>
                                        <div className={"container-fluid"}>
                                            <h1 style={{color:"#130071",fontSize:"37px", fontWeight:"700", lineHeight:"50px", textAlign:"center"}}>
                                                Gain access to unlimited user <br/>
                                                experience resources <br/>
                                                templates, courses, webinar <br/>
                                                recordings and more
                                            </h1>

                                            <Link to={"/join-rb"} style={{ textDecoration: 'none' }}>
                                                <p style={{textAlign:"center", fontSize:"14px", fontWeight:"500", lineHeight:"60px", color:"grey", textDecoration: null, marginBottom: "-8px"}}>
                                                    Plans now start at $6/month
                                                </p>
                                            </Link>

                                            <div className={"container d-flex justify-content-center px-5"}>
                                                <div className={"col-md-7 d-flex justify-content-center"}>

                                                    <div className={"col-md-9"}>
                                                        <Link to={"/join-rb"} className={"btn btn-get-started"} style={{fontSize: "18px"}}>
                                                            Start your free trial
                                                        </Link>
                                                    </div>

                                                    {/* <GoogleLogin
                                                    clientId = {this.state.clientID}
                                                    render={renderProps => (
                                                        <button onClick={renderProps.onClick} className="google-login1" style={{height:"59px"}}><img
                                                            className="google-icon"
                                                            src="https://upload.wikimedia.org/wikipedia/commons/5/53/Google_%22G%22_Logo.svg" style={{width:"25px"}}/> Sign up with Google </button>
                                                    )}
                                                    onSuccess = {onSuccess}
                                                    onFailure = {onFailure}
                                                    cookiePolicy = {'single_host_origin'}
                                                    isSignedIn={false}
                                                    accessType="offline"
                                                >
                                                </GoogleLogin>
*/}
                                                </div>
                                            </div>


                                            <p style={{color:"#130071", fontSize:"1.3rem", marginRight:"auto", marginLeft:"auto", width:"90%", lineHeight:"30px", fontWeight:"500", textAlign:"center", marginTop:"5.375rem"}}>
                                                Join thousands of UX professionals growing with Research Bookmark
                                            </p>
                                            <div className={"container-fluid"} style={{margin:" 0"}}>
                                                <Partners />
                                            </div>

                                        </div>

                                    </div>
                                </div>
                            </div>
                        </>
                    }
                </div>



            </>
        );
    }
}
export default NotLoggedInMainTablet