import React, {useEffect, useState, useCallback} from "react";
import YouTube from "react-youtube";
import skillshop from "../../../assets/Skillshop Fall 2022 (3).png";
import skillshop1 from "../../../assets/Skillshop Fall 2022 (4).png";
import LoginModal from "../Modals/LoginModal";
import MediaQuery from "react-responsive";
import January from "../../../assets/January Webinar (1).png"
import April from "../../../assets/RB April webinar.jpg"
import May from "../../../assets/RB May webinar.jpg"
import June from "../../../assets/RB Sept Webinar.png"
import September from "../../../assets/RB June webinar.png"
import how from"../../../assets/how to grow.jpg"
import Feb from "../../../assets/February Webinar.png"
import March from "../../../assets/March Webinar (1).png"
import Header from "../Landing/Header";
import {EventsHandler} from "../Landing/MainFile/MainFunctions";
import axios from "axios";
import buildAPIUrl from "../../../Services/UrlBuilder";
import Vimeo from '@u-wave/react-vimeo';
import UpgradeModal from "../Modals/UpgradeModal";


export default function Recordings(props){
    const [show, setShow]=useState(false)
    const [listOfWebinarRecordings, setlistOfWebinarRecordings] = useState([]);
    const [userToken, setUserToken]=useState(localStorage.getItem('token'))
    const [isLoading, setIsLoading] = useState(false);
    const [isError, setIsError] = useState(false);
    const [error, setError] = useState("");

    const handleModalOpen = ()=>{
        setShow(true)
    }
    const handleModalClose = ()=>{
        setShow(false)
    }
    const _onReady = (event) => {
        // access to player in all event handlers via event.target
        event.target.pauseVideo();
    }

    const fetchWebinarRecordingsHandler = useCallback(async () => {
        setIsLoading(true);
        setError(null);
        try {
          const response = await fetch(buildAPIUrl('v1/webinar_recordings/'),
          {headers: {Authorization: `Bearer ${userToken}`}});
          if (!response.ok) {
            throw new Error('Something went wrong!');
          }
    
          const data = await response.json();
    
          setlistOfWebinarRecordings(data);
        } catch (error) {
          setError(error.message);
        }
        setIsLoading(false);
      }, []);

    useEffect(()=>{
        const element = document.getElementById('recordings');
        if (element) {
            // 👇 Will scroll smoothly to the top of the next section
            element.scrollIntoView({ behavior: 'smooth' });
        }
        EventsHandler("Recordings link clicked",  document.URL)
        //Increase views by 1
        axios.get(buildAPIUrl('v1/collections/26/')).then(()=>{

        }).catch((error)=>{

        });
        fetchWebinarRecordingsHandler()

    }, [fetchWebinarRecordingsHandler])

    let content = <p></p>;

    if (listOfWebinarRecordings.length > 0) {
        content = listOfWebinarRecordings.map((recording)=>(
            
            <div className="col-lg-4 col-md-6 col-sm-12">
                <div className={"card"}>

                    <div className={"card-body"}>
                        {
                            localStorage.getItem('token') ? <>
                                <div className="previous card">
                                <Vimeo
                                    video={recording.link}
                                
                                    height={10000}
                                    />
                                </div>
                            </>:<>
                                {/* <img onClick={handleModalOpen} 
                                src="https://res.cloudinary.com/analogteams/image/upload/v1680268136/March_30th_Webinar_t9r9fa.png"
                                style={{width:"100%", cursor:"pointer"}}/> */}
                            </>
                        }

                    </div>
                    <div className={"card-footer bg-white border-top-0"}>
                        <h6 className={"mb-3"} >
                            {recording.title}
                        </h6>
                    </div>
                </div>

            </div>

        ));
    }
    else if(isLoading){
        content = <p>Loading...</p>;
    }
    else if(isError){
        content = <p>{error}</p>;
    }
    else{
        content = <p>Found no Recordings.</p>;
    }
    return (
        <>
            <LoginModal openLoginModal={handleModalOpen}
                        closeLoginModal={handleModalClose}
                        show={show}/>
            <Header />
            <UpgradeModal />
            <MediaQuery minWidth={1200} >
                <div className={"webinar-content" } style={{marginTop:"7rem"}}>
                    <h6 id={"recordings"}  className="text-center">
                        The recordings are out!
                    </h6>
                    <div className="row mt-3 recordings">
                        
                    {content}
                   
                    
                        
                    </div>
                </div>

            </MediaQuery>
            <MediaQuery maxWidth={460}>
                <center>
                <div className={"webinar-content" } style={{width: "100%", marginLeft: "-2%"}}>
                    <h6 className="text-center">
                        The recordings are out!
                    </h6>
                    <div className="row mt-3 recordings">
                    {content}
                       
                    </div>
                </div>
                </center>
            </MediaQuery>
            <MediaQuery maxWidth={1200} minWidth={461}>
                <div className={"webinar-content" } style={{marginRight:"3rem"}}>
                    <h6 className="text-center">
                        The recordings are out!
                    </h6>
                    <div className="row mt-3 recordings">
                    
                        {content}
               
                    {/* <div className="col-md-6 ">
                            <div className={"card"}>

                                <div className={"card-body"}>
                                    {
                                        localStorage.getItem('token') ? <>
                                            <div className="previous card">
                                            <Vimeo
                                                video="https://player.vimeo.com/video/817980577?h=a260fde091&amp;badge=0&amp;autopause=0&amp;player_id=0&amp;app_id=58479"
                                           
                                                />

                                            </div>
                                        </>:<>
                                           
                                        </>
                                    }

                                </div>
                                <div className={"card-footer bg-white border-top-0"}>
                                    <h6 className={"mb-3"} >
                                        UX Skillshop Day 2 [2023]
                                    </h6>
                                </div>
                            </div>

                    </div> */}
                      
                    </div>
                </div>
            </MediaQuery>

        </>
    )
}