import React, {Component} from "react";
import Logo from "../../../assets/research-bookmark-logotype-one-color-rgb.webp"
import {FaLinkedin, FaTwitter} from "react-icons/fa";
import {MdMailOutline} from "react-icons/md";
import {Link} from "react-router-dom";
import MediaQuery from "react-responsive";
class Footer extends Component{
    render() {
        return (
            <>
                <MediaQuery minWidth={1200}  >
                    <footer className="container-fluid" style={{marginTop:"0"}}>
                        <div className="container-fluid m-0 px-5">
                            <img alt={"footer logo"} className="footer-logo" src={Logo}/>
                        </div>
                        <div className="container-fluid px-5 m-0">
                            <div className="row">
                                <div className="col-md-4">
                                    <div className={"row mt-4"}>
                                        {/* <div className={"col-md-6"}>
                                            <Link to="/support_rb"  style={{color:"#333333",textDecoration:"none"}} >Support RB</Link>
                                        </div> */}
                                        <div className={"col-md-6"}>
                                            <Link to="/collections"  style={{color:"#333333",textDecoration:"none"}} >RB Collections</Link>
                                        </div>

                                    </div>
                                        <div className={"row mt-4"}>
                                            <div className={"col-md-6"}>
                                                <Link to="/features"  style={{color:"#333333",textDecoration:"none"}} >Features</Link>
                                            </div>

                                          {/*  <div className={"col-md-8"}>
                                               <a  href="https://www.researchbookmark.io/webinar" style={{color:"#333333", textDecoration:"none"}}>Register for our December workshop</a>
                                            </div>
*/}
                                            <div className={"col-md-6"}>
                                                <Link to="/webinar"  style={{color:"#333333",textDecoration:"none"}} >Upcoming Webinar</Link>
                                            </div>

                                        </div>

                                        <div className={"row mt-4"}>
                                            <div className={"col-md-6"}>
                                                <Link to="/participate-in-research"  style={{color:"#333333",textDecoration:"none"}} >Participate in Research</Link>
                                            </div>

                                            <div className={"col-md-6"}>
                                                <Link  to="/terms-of-use" style={{color:"#333333", textDecoration:"none"}}>Terms & conditions</Link>
                                            </div>
                                        </div>
                                    <div className={"row mt-4"}>

                                        <div className={"col-md-6"}>   <Link to="/privacy-policy"  style={{color:"#333333", textDecoration:"none"}} >Privacy Policy </Link></div>
                                    </div>

                                </div>
                                <div className={"col-md-3"}>
                                   <span><a href="https://twitter.com/DaBookmark" target="_blank" style={{color:"#333333", textDecoration:"none"}}> <FaTwitter /></a></span>

                                    <span><a style={{color:"#333333"}} href="https://www.linkedin.com/company/research-bookmark/" target="_blank"><FaLinkedin /></a> </span><span style={{color:"#333333"}}><a style={{color:"#333333"}} href="mailto:hello@researchbookmark.io" target="_blank"><MdMailOutline /></a> </span>
                                </div>
                                <div className="col-md-3 col-12 d-flex  align-items-center">
                                    <a href="https://www.producthunt.com/posts/research-bookmark?utm_source=badge-featured&utm_medium=badge&utm_souce=badge-research&#0045;bookmark" target="_blank"><img src="https://api.producthunt.com/widgets/embed-image/v1/featured.svg?post_id=366798&theme=light" alt="Research&#0032;Bookmark - A&#0032;digital&#0032;archive&#0032;for&#0032;User&#0032;Research&#0032;knowledge&#0046; | Product Hunt" style={{width: "250px", height: "54px"}} /></a>
                                </div>
                            </div>

                        </div>
                        <div className="container-fluid text-center mt-5 border-top pt-4">
                            <h6> &copy; Research Bookmark. All rights reserved.</h6>
                        </div>

                    </footer>
                </MediaQuery>
                <MediaQuery maxWidth={460}>
                    <div className="mobile">
                        <footer className="container-fluid" style={{padding:"2rem 1rem !important"}}>
                            <div className="container-fluid p-0 m-0">
                                <img alt={"footer logo"} className="footer-logo" src={Logo}/>
                            </div>
                            <div className="container-fluid p-0 m-0">
                                <div className="row">
                                    <div className="col-md-6">
                                        <div className={"row mt-4"}>
                                           {/* <div className={"col-md-4 mt-2"}>
                                                <Link to="/support_rb"  style={{color:"#333333",textDecoration:"none"}} >Support RB</Link>
                                            </div> */}
                                            <div className={"col-md-3 mt-2"}>
                                                <Link to="/collections"  style={{color:"#333333",textDecoration:"none"}} >RB Collections</Link>
                                            </div>



                                            <div className={"col-md-4 mt-2"}>
                                                <Link to="/features"  style={{color:"#333333",textDecoration:"none"}} >Features</Link>
                                            </div>

                                            {/*  <div className={"col-md-8"}>
                                               <a  href="https://www.researchbookmark.io/webinar" style={{color:"#333333", textDecoration:"none"}}>Register for our December workshop</a>
                                            </div>
*/}
                                            <div className={"col-md-4 mt-2"}>
                                                <Link to="/webinar"  style={{color:"#333333",textDecoration:"none"}} >Upcoming Webinar</Link>
                                            </div>




                                            <div className={"col-md-4 mt-2"}>
                                                <Link to="/participate-in-research"  style={{color:"#333333",textDecoration:"none"}} >Participate in Research</Link>
                                            </div>

                                            <div className={"col-md-3 mt-2"}>
                                                <Link  to="/terms-of-use" style={{color:"#333333", textDecoration:"none"}}>Terms & conditions</Link>
                                            </div>



                                            <div className={"col-md-2 mt-2"}>   <Link to="/privacy-policy"  style={{color:"#333333", textDecoration:"none"}} >Privacy Policy </Link></div>

                                        </div>
                                    </div>
                                    <div className={"col-md-3 d-flex  align-items-center mt-3 mb-3"}>
                                        <span><a href="https://twitter.com/DaBookmark" target="_blank" style={{color:"#333333", textDecoration:"none"}}> <FaTwitter /></a></span><span><a style={{color:"#333333"}} href="https://www.linkedin.com/company/research-bookmark/" target="_blank"><FaLinkedin /></a> </span><span style={{color:"#333333"}}><a style={{color:"#333333"}} href="mailto:hello@researchbookmark.io" target="_blank"><MdMailOutline /></a> </span>
                                    </div>
                                    <div className="col-md-3 col-12 d-flex  align-items-center">
                                        <a href="https://www.producthunt.com/posts/research-bookmark?utm_source=badge-featured&utm_medium=badge&utm_souce=badge-research&#0045;bookmark" target="_blank"><img src="https://api.producthunt.com/widgets/embed-image/v1/featured.svg?post_id=366798&theme=light" alt="Research&#0032;Bookmark - A&#0032;digital&#0032;archive&#0032;for&#0032;User&#0032;Research&#0032;knowledge&#0046; | Product Hunt" style={{width: "250px", height: "54px"}} /></a>
                                    </div>
                                </div>

                            </div>
                            <div className="container-fluid text-center mt-5 border-top pt-4">
                                <h6> &copy; Research Bookmark. All rights reserved.</h6>
                            </div>

                        </footer>
                    </div>
                </MediaQuery>
                <MediaQuery maxWidth={1200} minWidth={461} >
                    <div className={"tablet"}>
                        <footer className="container-fluid">
                            <div className="container-fluid p-0 m-0">
                                <img alt={"footer logo"} className="footer-logo" src={Logo}/>
                            </div>
                            <div className="container-fluid p-0 m-0">
                                <div className="row">
                                    <div className="col-md-8">
                                        <div className={"row mt-4"}>
                                           {/* <div className={"col-md-6"}>
                                                <Link to="/support_rb"  style={{color:"#333333",textDecoration:"none"}} >Support RB</Link>
                                            </div> */}
                                            <div className={"col-md-6"}>
                                                <Link to="/collections"  style={{color:"#333333",textDecoration:"none"}} >RB Collections</Link>
                                            </div>

                                        </div>
                                        <div className={"row mt-4"}>
                                            <div className={"col-md-6"}>
                                                <Link to="/features"  style={{color:"#333333",textDecoration:"none"}} >Features</Link>
                                            </div>

                                            {/*  <div className={"col-md-8"}>
                                               <a  href="https://www.researchbookmark.io/webinar" style={{color:"#333333", textDecoration:"none"}}>Register for our December workshop</a>
                                            </div>
*/}
                                            <div className={"col-md-6"}>
                                                <Link to="/webinar"  style={{color:"#333333",textDecoration:"none"}} >Upcoming Webinar</Link>
                                            </div>

                                        </div>

                                        <div className={"row mt-4"}>
                                            <div className={"col-md-6"}>
                                                <Link to="/participate-in-research"  style={{color:"#333333",textDecoration:"none"}} >Participate in Research</Link>
                                            </div>

                                            <div className={"col-md-6"}>
                                                <Link  to="/terms-of-use" style={{color:"#333333", textDecoration:"none"}}>Terms & conditions</Link>
                                            </div>
                                        </div>
                                        <div className={"row mt-4"}>

                                            <div className={"col-md-6"}>   <Link to="/privacy-policy"  style={{color:"#333333", textDecoration:"none"}} >Privacy Policy </Link></div>
                                        </div>

                                    </div>
                                    <div className="col-md-4 col-12 ">
                                        <div className={"container"}>
                                        <span><a href="https://twitter.com/DaBookmark" target="_blank" style={{color:"#333333", textDecoration:"none"}}> <FaTwitter /></a></span><span><a style={{color:"#333333"}} href="https://www.linkedin.com/company/research-bookmark/" target="_blank"><FaLinkedin /></a> </span><span style={{color:"#333333"}}><a style={{color:"#333333"}} href="mailto:hello@researchbookmark.io" target="_blank"><MdMailOutline /></a> </span>
                                        </div>
                                            <a href="https://www.producthunt.com/posts/research-bookmark?utm_source=badge-featured&utm_medium=badge&utm_souce=badge-research&#0045;bookmark" target="_blank"><img src="https://api.producthunt.com/widgets/embed-image/v1/featured.svg?post_id=366798&theme=light" alt="Research&#0032;Bookmark - A&#0032;digital&#0032;archive&#0032;for&#0032;User&#0032;Research&#0032;knowledge&#0046; | Product Hunt" style={{width: "250px", height: "54px"}} /></a>
                                    </div>
                                </div>

                            </div>
                            <div className="container-fluid text-center mt-5 border-top pt-4">
                                <h6> &copy; Research Bookmark. All rights reserved.</h6>
                            </div>

                        </footer>
                    </div>
                </MediaQuery>
            </>
        )
    }
}
export default Footer