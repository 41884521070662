import { GoogleLogin } from "react-google-login";
import axios from "axios";
import buildAPIUrl from "../../../Services/UrlBuilder";
import { EventsHandler } from "../Landing/MainFile/MainFunctions";
import swal from "sweetalert";
import React, { useState } from "react";

export default function GoogleButton(props) {
    const [clientID, setClientID] = useState("425793011868-1s6v97ajh0m70dhcvb7oejp3qup4qb5e.apps.googleusercontent.com");






    if (props.type === "normal") {

    }

    const onSuccess = async (res) => {
        var myHeaders = new Headers();
        myHeaders.append("Content-Type", "application/x-www-form-urlencoded");
        var requestOptions = {
            method: 'POST',
            headers: myHeaders,
            redirect: 'follow'
        };
        localStorage.setItem('calendar_token', res.accessToken)
        localStorage.setItem('token', res.accessToken)
        localStorage.setItem('username', res.profileObj.givenName)
        localStorage.setItem('image', res.profileObj.imageUrl)
        localStorage.setItem('email', res.profileObj.email)
        await axios.post(buildAPIUrl('v1/social_auth/convert-token'), {
            token: localStorage.getItem('token'),
            client_id: process.env.REACT_APP_SOCIAL_AUTH_CLIENT_ID,
            client_secret: process.env.REACT_APP_SOCIAL_AUTH_CLIENT_SECRET,
            grant_type: "convert_token",
            backend: "google-oauth2"
        }, requestOptions).then((response) => {
            localStorage.setItem('token', response.data.access_token)
            localStorage.setItem('refresh_token', response.data.refresh_token)
            EventsHandler(`Logged in using Google`, document.URL)
            axios.get(buildAPIUrl('v1/users/me/'), {
                headers: {
                    Authorization: `Bearer ${response.data.access_token}`
                }
            }).then((res) => {
                if (res.data.company) {
                    localStorage.setItem('profileupdate', "1")
                }
                window.location.assign("/")
            })
        }).catch((err) => {
            swal({
                title: "Opps!",
                text: "The selected email already exists",
                icon: "error",
                button: "Close",
            }).then((resp) => {
                window.location.assign("/")
                localStorage.clear()
            });
            console.log(err)
        })
    }
    const onFailure = (res) => {
        console.log(res)
        console.log("Login Failed")
    }


    return (
        <>
            <GoogleLogin
                clientId={clientID}
                render={renderProps => (
                    <button onClick={renderProps.onClick} className="google-login1" disabled={renderProps.disabled}
                    >
                        <img
                            className="google-icon"
                            src="https://upload.wikimedia.org/wikipedia/commons/5/53/Google_%22G%22_Logo.svg"
                            style={{ width: "25px" }} /> {renderProps.disabled ? "Loading..." : "Continue with Google" } </button>

                )}
                onSuccess={onSuccess}
                onFailure={onFailure}
                cookiePolicy={'single_host_origin'}
                isSignedIn={false}
                accessType="offline"
                prompt={"select_account"}


            >
            </GoogleLogin>
        </>
    )
}