import React, {Component} from "react";
import {Modal} from "react-bootstrap";
import {GoogleLogin} from "react-google-login";
import FacebookLogin from "react-facebook-login";
import axios from "axios";
import swal from "sweetalert";
import Logo from "../../../assets/research-bookmark-logotype-reverse-rgb 2.png"
import buildAPIUrl from "../../../Services/UrlBuilder";
import modalimage from "../../../assets/Group 89937.png"
import MediaQuery from "react-responsive";
import {MdCancel} from "react-icons/md";
import bookmark from "../../../assets/Frame 89683.png"
import {EventsHandler} from "../Landing/MainFile/MainFunctions";

class LoginModal extends Component{
    state = {
        show: this.props.show,
        clientID: process.env.REACT_APP_GOOGLE_CLIENT_ID,
        regshow:false,
        showloginmodal: localStorage.getItem('showloginmodal'),
        token:localStorage.getItem('token'),
        showmodal:false
    }
    reghandleOpen = () => {
        this.setState({
            show: false,
            regshow:true
        })
    }
    reghandleClose = () => {
        console.log("close")
        this.setState({
            regshow: false
        })
    }
    handleOpen = () => {
        this.setState({
            show: true
        })
    }
    handleClose = () => {
        console.log("close")
        this.setState({
            show: false
        })
    }
    openloginmodal = () => {
        this.props.openLoginModal()
    }
    closeloginmodal = () => {
        localStorage.setItem('showloginmodal', "1")
        this.setState({
            showmodal:false
        })
        this.props.closeLoginModal()
    }
    componentDidMount() {

    }
    handleLoginModalClose = () => {
        localStorage.setItem('showloginmodal', "1")
        this.setState({
            showmodal:false,
        })
    }
    render() {
        const onSuccess = async (res) => {
            var myHeaders = new Headers();
            myHeaders.append("Content-Type", "application/x-www-form-urlencoded");
            var requestOptions = {
                method: 'POST',
                headers: myHeaders,
                redirect: 'follow'
            };
            localStorage.setItem('calendar_token',res.accessToken)
            localStorage.setItem('token', res.accessToken)
            localStorage.setItem('username', res.profileObj.givenName)
            localStorage.setItem('image', res.profileObj.imageUrl)
            localStorage.setItem('email', res.profileObj.email)
            const resp = await axios.post(buildAPIUrl('v1/social_auth/convert-token'), {
                token: localStorage.getItem('token'),
                client_id: process.env.REACT_APP_SOCIAL_AUTH_CLIENT_ID,
                client_secret: process.env.REACT_APP_SOCIAL_AUTH_CLIENT_SECRET,
                grant_type:"convert_token",
                backend: "google-oauth2"
            }, requestOptions).then((response)=>{
                localStorage.setItem('token', response.data.access_token)
                localStorage.setItem('refresh_token', response.data.refresh_token)
                EventsHandler(`Logged in using Google`, document.URL)
                window.location.reload()
            }).catch((err)=>{
                swal({
                    title: "Opps!",
                    text: "The selected email already exists",
                    icon: "error",
                    button: "Close",
                }).then((resp)=>{
                    window.location.assign("/")
                    localStorage.clear()
                });

            })
        }
        const onFailure = (res) => {
            console.log(res)
            console.log("Login Failed")
        }
        const facebookLogin = async (res) => {
            var myHeaders = new Headers();
            myHeaders.append("Content-Type", "application/x-www-form-urlencoded");
            var requestOptions = {
                method: 'POST',
                headers: myHeaders,
                redirect: 'follow'
            };
            localStorage.setItem('token', res.accessToken)
            localStorage.setItem('username', res.name)
            localStorage.setItem('image', res.picture.data.url)
            localStorage.setItem('email', res.email)
            await axios.post(buildAPIUrl('v1/social_auth/convert-token'), {
                token: localStorage.getItem('token'),
                client_id: process.env.SOCIAL_AUTH_CLIENT_ID,
                client_secret: process.env.SOCIAL_AUTH_CLIENT_SECRET,
                grant_type:"convert_token",
                backend: "facebook"
            }, requestOptions).then((response)=>{
                axios.get(buildAPIUrl('v1/users/me/'), {
                    headers:{
                        Authorization: `Bearer ${response.data.access_token}`
                    }
                }).then((res)=>{
                    if (res.data.company){
                        localStorage.setItem('profileupdate', "1")
                    }
                    window.location.assign("/")
                })
                EventsHandler(`Logged in using Facebook`, document.URL)
                localStorage.setItem('token', response.data.access_token)
                localStorage.setItem('refresh_token', response.data.refresh_token)

            }).catch((err)=>{
                swal({
                    title: "Opps!",
                    text: "The selected email already exists",
                    icon: "error",
                    button: "Close",
                }).then((resp)=>{
                    window.location.reload()
                    localStorage.clear()
                });
                console.log(err)
            })
        }
        return (

            <>
                <MediaQuery minWidth={1200}>
                    <div className="authentication-modals">
                        <Modal size="lg" centered show={this.props.show || this.state.showmodal} onHide={this.closeloginmodal} >
                            <form onSubmit={this.handleUpload}>
                                <Modal.Body className={"p-0"} style={{overflow:"hidden", borderRadius:"20px"}}>

                                    <div className="row">
                                        <div style={{background:"rgba(29, 29, 29)"}} className={this.props.device==="desktop" || this.props.device==="tablet" ? "col-md-6 modal-right " : "col-md-6 modal-right "  }>
                                            <img src={Logo} style={{width:"100px"}}/>
                                            <div className="container-fluid text-center">

                                                <img src={modalimage} style={{marginTop:"1.375rem"}}/>

                                                <p>
                                                    Gain access to:
                                                </p>
                                                <h6>
                                                    <span><img className={"bookmark"} src={bookmark}/></span>Ad-free search results
                                                </h6>
                                                <h6>  <span><img className={"bookmark"} src={bookmark}/></span> Useful free UXR resources</h6>
                                                <h6> <span><img className={"bookmark"} src={bookmark}/></span> Carefully curated UXR conferences</h6>
                                                <h6> <span><img className={"bookmark"} src={bookmark}/></span> Free monthly webinars featuring industry thought leaders</h6>
                                            </div>
                                        </div>
                                        <div className={ "col-md-6 modal-left d-flex align-items-lg-center position-relative"}>
                                            <h4 className={"position-absolute"} style={{right:"2rem", top:"2rem", marginLeft:"0", textDecoration:"underline"}} onClick={this.closeloginmodal}><MdCancel /></h4>
                                            <div>

                                                <div className="card d-flex align-items-center " style={{marginLeft:"1rem", marginRight:"1.25rem", marginTop:"4.563rem", marginBottom:"0.313rem"}}>

                                                    <div className={"container-fluid d-flex align-items-center"}>
                                                        <div>

                                                            <h5 style={{fontSize:"30px", lineHeight:"43px", fontWeight:"600", textAlign:"center"}}>Access more with an RB account  </h5>

                                                            <div className="container-fluid p-0 flex-column d-flex justify-content-center">
                                                                <div className=" mb-2 pt-2" >
                                                                    <GoogleLogin
                                                                        clientId = {this.state.clientID}
                                                                        render={renderProps => (
                                                                            <button onClick={renderProps.onClick} className="google-login1"><img
                                                                                className="google-icon"
                                                                                src="https://upload.wikimedia.org/wikipedia/commons/5/53/Google_%22G%22_Logo.svg" style={{width:"25px"}}/> Continue with Google </button>
                                                                        )}
                                                                        onSuccess = {onSuccess}
                                                                        onFailure = {onFailure}
                                                                        cookiePolicy = {'single_host_origin'}
                                                                        isSignedIn={false}
                                                                        accessType="offline"
                                                                    >
                                                                    </GoogleLogin>

                                                                </div>
                                                                <div className=" mb-3 d-flex justify-content-center facebook-login">
                                                                    <FacebookLogin
                                                                        textButton="Continue with Facebook"
                                                                        appId="714881769793978"
                                                                        autoLoad={false}
                                                                        fields="name,email,picture"
                                                                        callback={facebookLogin}
                                                                        onSuccess={facebookLogin}
                                                                        cssClass="my-facebook-button-class1 mt-3"
                                                                        icon={<span className="social-media-facebook">
                                                            <img src="https://upload.wikimedia.org/wikipedia/en/thumb/0/04/Facebook_f_logo_%282021%29.svg/1200px-Facebook_f_logo_%282021%29.svg.png" style={{width:"25px"}}/>
                                                        </span>}
                                                                    >
                                                                    </FacebookLogin>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>



                                                </div>
                                                <div className={"container-fluid d-flex justify-content-end mt-3"} style={{background:"transparent",zIndex:"-10"}}>

                                                </div>
                                            </div>

                                        </div>


                                    </div>

                                </Modal.Body>
                            </form>
                        </Modal>
                        <Modal size="lg" centered className="hidden-mob"  show={this.state.regshow}  onHide={this.reghandleClose}>
                            <form onSubmit={this.handleUpload}>
                                <Modal.Body >
                                    <div className="row" style={{borderRadius:"8px"}}>
                                        <div className="col-md-6 p-0 m-0 modal-left">

                                            <div className="card border-0" style={{marginLeft:"3rem", marginRight:"2.25rem", marginTop:"2.563rem", marginBottom:"2.313rem"}}>
                                                <img src={Logo} style={{width:"100px", height:"50px"}}/>
                                                <h6 className="">Signup to continue {this.props.type ==="1"? "saving" : this.props.type==="2" ? "filtering" : "browsing"} resources on Research Bookmark</h6>
                                                <br/>
                                                <div className=" mb-2 pt-2" >
                                                    <GoogleLogin
                                                        clientId = {this.state.clientID}
                                                        render={renderProps => (
                                                            <button onClick={renderProps.onClick} className="google-login border"><img
                                                                className="google-icon"
                                                                src="https://upload.wikimedia.org/wikipedia/commons/5/53/Google_%22G%22_Logo.svg" style={{width:"15px"}}/> Sign in with Google </button>
                                                        )}
                                                        onSuccess = {onSuccess}
                                                        onFailure = {onFailure}
                                                        cookiePolicy = {'single_host_origin'}
                                                        isSignedIn={false}
                                                        accessType="offline"

                                                    >
                                                    </GoogleLogin>

                                                </div>
                                                <div className=" mb-3 d-flex justify-content-center facebook-login">
                                                    <FacebookLogin
                                                        textButton="Sign in with Facebook"
                                                        appId="714881769793978"
                                                        autoLoad={false}
                                                        fields="name,email,picture"
                                                        callback={facebookLogin}
                                                        onSuccess={facebookLogin}
                                                        cssClass="my-facebook-button-class mt-3 border"
                                                        icon={<span className="social-media-facebook">
                                                            <img src="https://upload.wikimedia.org/wikipedia/en/thumb/0/04/Facebook_f_logo_%282021%29.svg/1200px-Facebook_f_logo_%282021%29.svg.png" style={{width:"15px"}}/>
                                                        </span>}
                                                    >
                                                    </FacebookLogin>
                                                </div>
                                                <div className="mt-3 mb-5 text-center" style={{marginTop:"1rem"}}>
                                                    <h6 className="mobmodal donot">Already have an account? <span onClick={this.reghandleClose} style={{color:"#00944d", cursor:"pointer"}}>Login</span></h6>
                                                </div>

                                            </div>
                                        </div>
                                        <div className="col-md-6 modal-right" >
                                            <div className="container-fluid text-center ">
                                                <img src={modalimage}/>

                                                <p>
                                                    You will gain access to:
                                                </p>
                                                <h6>
                                                    -Ad-free search results
                                                </h6>
                                                <h6>-Useful free UXR resources</h6>
                                                <h6>-Carefully curated UXR conferences</h6>
                                                <h6>-Free monthly webinars featuring industry thought leaders</h6>
                                            </div>
                                        </div>
                                    </div>

                                </Modal.Body>
                            </form>
                        </Modal>
                    </div>
                </MediaQuery>
                <MediaQuery maxWidth={460} className="mobile">
                    <div className="authentication-modals">
                        <Modal size="lg" className={"mod-1"} centered show={this.props.show || this.state.showmodal} onHide={this.closeloginmodal} >
                            <form onSubmit={this.handleUpload}>
                                <Modal.Body className={"p-0"} style={{overflow:"hidden", borderRadius:"20px"}}>

                                    <div className="row">
                                        <div className={ "col-md-12 modal-left d-flex align-items-lg-center position-relative"}>
                                            <h4 className={"position-absolute"} style={{right:"2rem", top:"2rem", marginLeft:"0", textDecoration:"underline"}} onClick={this.closeloginmodal}><MdCancel /></h4>
                                            <div>

                                                <div className="card d-flex align-items-center " style={{marginLeft:"1rem", marginRight:"1.25rem", marginTop:"4.563rem", marginBottom:"0.313rem"}}>

                                                    <div className={"container-fluid d-flex align-items-center"}>
                                                        <div>
                                                            <h5 style={{fontSize:"30px", lineHeight:"43px", fontWeight:"600", textAlign:"center"}}>Access more with an RB account</h5>

                                                            <div className="container-fluid p-0 flex-column d-flex justify-content-center">
                                                                <div className=" mb-2 pt-2" >
                                                                    <GoogleLogin
                                                                        clientId = {this.state.clientID}
                                                                        render={renderProps => (
                                                                            <button onClick={renderProps.onClick} className="google-login1"><img
                                                                                className="google-icon"
                                                                                src="https://upload.wikimedia.org/wikipedia/commons/5/53/Google_%22G%22_Logo.svg" style={{width:"25px"}}/> Continue with Google </button>
                                                                        )}
                                                                        onSuccess = {onSuccess}
                                                                        onFailure = {onFailure}
                                                                        cookiePolicy = {'single_host_origin'}
                                                                        isSignedIn={false}
                                                                        accessType="offline"
                                                                    >
                                                                    </GoogleLogin>

                                                                </div>
                                                                <div className=" mb-3 d-flex justify-content-center facebook-login">
                                                                    <FacebookLogin
                                                                        textButton="Continue with Facebook"
                                                                        appId="714881769793978"
                                                                        autoLoad={false}
                                                                        fields="name,email,picture"
                                                                        callback={facebookLogin}
                                                                        onSuccess={facebookLogin}
                                                                        cssClass="my-facebook-button-class1 mt-3"
                                                                        icon={<span className="social-media-facebook">
                                                            <img src="https://upload.wikimedia.org/wikipedia/en/thumb/0/04/Facebook_f_logo_%282021%29.svg/1200px-Facebook_f_logo_%282021%29.svg.png" style={{width:"25px"}}/>
                                                        </span>}
                                                                    >
                                                                    </FacebookLogin>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>



                                                </div>
                                                <div className={"container-fluid d-flex justify-content-end mt-3"} style={{background:"transparent",zIndex:"-10"}}>

                                                </div>
                                            </div>

                                        </div>


                                    </div>

                                </Modal.Body>
                            </form>
                        </Modal>
                    </div>
                </MediaQuery>
                <MediaQuery maxWidth={1200} className="tablet" minWidth={461}>
                    <div className="authentication-modals">
                        <Modal size="md" centered show={this.props.show || this.state.showmodal} onHide={this.closeloginmodal} >
                            <form onSubmit={this.handleUpload}>
                                <Modal.Body className={"p-0"} style={{overflow:"hidden", borderRadius:"20px"}}>

                                    <div className="row">
                                        <div className={ "col-md-12 modal-left d-flex align-items-lg-center position-relative"}>
                                            <h4 className={"position-absolute"} style={{right:"2rem", top:"2rem", marginLeft:"0", textDecoration:"underline"}} onClick={this.closeloginmodal}><MdCancel /></h4>
                                            <div>

                                                <div className="card d-flex align-items-center " style={{marginLeft:"1rem", marginRight:"1.25rem", marginTop:"4.563rem", marginBottom:"0.313rem"}}>

                                                    <div className={"container-fluid d-flex align-items-center"}>
                                                        <div>
                                                            <h5 style={{fontSize:"30px", lineHeight:"43px", fontWeight:"600", textAlign:"center"}}>Access more with an RB account</h5>

                                                            <div className="container-fluid p-0 flex-column d-flex justify-content-center">
                                                                <div className=" mb-2 pt-2" >
                                                                    <GoogleLogin
                                                                        clientId = {this.state.clientID}
                                                                        render={renderProps => (
                                                                            <button onClick={renderProps.onClick} className="google-login1"><img
                                                                                className="google-icon"
                                                                                src="https://upload.wikimedia.org/wikipedia/commons/5/53/Google_%22G%22_Logo.svg" style={{width:"25px"}}/> Continue with Google </button>
                                                                        )}
                                                                        onSuccess = {onSuccess}
                                                                        onFailure = {onFailure}
                                                                        cookiePolicy = {'single_host_origin'}
                                                                        isSignedIn={false}
                                                                        accessType="offline"
                                                                    >
                                                                    </GoogleLogin>

                                                                </div>
                                                                <div className=" mb-3 d-flex justify-content-center facebook-login">
                                                                    <FacebookLogin
                                                                        textButton="Continue with Facebook"
                                                                        appId="714881769793978"
                                                                        autoLoad={false}
                                                                        fields="name,email,picture"
                                                                        callback={facebookLogin}
                                                                        onSuccess={facebookLogin}
                                                                        cssClass="my-facebook-button-class1 mt-3"
                                                                        icon={<span className="social-media-facebook">
                                                            <img src="https://upload.wikimedia.org/wikipedia/en/thumb/0/04/Facebook_f_logo_%282021%29.svg/1200px-Facebook_f_logo_%282021%29.svg.png" style={{width:"25px"}}/>
                                                        </span>}
                                                                    >
                                                                    </FacebookLogin>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>



                                                </div>
                                                <div className={"container-fluid d-flex justify-content-end mt-3"} style={{background:"transparent",zIndex:"-10"}}>

                                                </div>
                                            </div>

                                        </div>


                                    </div>

                                </Modal.Body>
                            </form>
                        </Modal>
                        <Modal size="lg" centered className="hidden-mob"  show={this.state.regshow}  onHide={this.reghandleClose}>
                            <form onSubmit={this.handleUpload}>
                                <Modal.Body >
                                    <div className="row" style={{borderRadius:"8px"}}>
                                        <div className="col-md-6 p-0 m-0 modal-left">

                                            <div className="card border-0" style={{marginLeft:"3rem", marginRight:"2.25rem", marginTop:"2.563rem", marginBottom:"2.313rem"}}>
                                                <img src={Logo} style={{width:"100px", height:"50px"}}/>
                                                <h6 className="">Signup to continue {this.props.type ==="1"? "saving" : this.props.type==="2" ? "filtering" : "browsing"} resources on Research Bookmark</h6>
                                                <br/>
                                                <div className=" mb-2 pt-2" >
                                                    <GoogleLogin
                                                        clientId = {this.state.clientID}
                                                        render={renderProps => (
                                                            <button onClick={renderProps.onClick} className="google-login border"><img
                                                                className="google-icon"
                                                                src="https://upload.wikimedia.org/wikipedia/commons/5/53/Google_%22G%22_Logo.svg" style={{width:"15px"}}/> Sign in with Google </button>
                                                        )}
                                                        onSuccess = {onSuccess}
                                                        onFailure = {onFailure}
                                                        cookiePolicy = {'single_host_origin'}
                                                        isSignedIn={false}
                                                        accessType="offline"

                                                    >
                                                    </GoogleLogin>

                                                </div>
                                                <div className=" mb-3 d-flex justify-content-center facebook-login">
                                                    <FacebookLogin
                                                        textButton="Sign in with Facebook"
                                                        appId="714881769793978"
                                                        autoLoad={false}
                                                        fields="name,email,picture"
                                                        callback={facebookLogin}
                                                        onSuccess={facebookLogin}
                                                        cssClass="my-facebook-button-class mt-3 border"
                                                        icon={<span className="social-media-facebook">
                                                            <img src="https://upload.wikimedia.org/wikipedia/en/thumb/0/04/Facebook_f_logo_%282021%29.svg/1200px-Facebook_f_logo_%282021%29.svg.png" style={{width:"15px"}}/>
                                                        </span>}
                                                    >
                                                    </FacebookLogin>
                                                </div>
                                                <div className="mt-3 mb-5 text-center" style={{marginTop:"1rem"}}>
                                                    <h6 className="mobmodal donot">Already have an account? <span onClick={this.reghandleClose} style={{color:"#00944d", cursor:"pointer"}}>Login</span></h6>
                                                </div>

                                            </div>
                                        </div>
                                        <div className="col-md-6 modal-right" >
                                            <div className="container-fluid text-center ">
                                                <img src={modalimage}/>

                                                <p>
                                                    You will gain access to:
                                                </p>
                                                <h6>
                                                    -Ad-free search results
                                                </h6>
                                                <h6>-Useful free UXR resources</h6>
                                                <h6>-Carefully curated UXR conferences</h6>
                                                <h6>-Free monthly webinars featuring industry thought leaders</h6>
                                            </div>
                                        </div>
                                    </div>

                                </Modal.Body>
                            </form>
                        </Modal>
                    </div>
                </MediaQuery>
            </>
        )
    }
}
export default LoginModal