import React, {useEffect, useState} from "react";
import axios from "axios";
import buildAPIUrl from "../../../Services/UrlBuilder";
import MediaQuery from "react-responsive";

export default function PopularBanner(props){
    const[popular, setPopular]=useState([])
    //Get popular / featured banners
    const getPopular = async () =>{
        try {
            const popular = await axios.get(buildAPIUrl(`v1/featured_post/?client_name=${props.name}`))
            setPopular(popular.data)
        } catch (error){

        }

    }
    useEffect(()=>{
        getPopular()
    }, [])
    return (
        <>
            <MediaQuery minWidth={1200}>

                <div className={"row"}>
                    {
                        popular.length > 0 ?  <h6 style={{fontSize:"14px", marginLeft:"0", fontWeight:"500", marginTop:"2.125rem"}}>Popular & Trending</h6> : ""
                    }


                    {
                        popular ?  <>

                            {
                                popular.slice(0,2).map((popular)=>(

                                    <div className={props.columns==="2" ? "col-md-6": "col-md-12"}>
                                        <a href={popular.url} target={"_blank"}>
                                            <img className={"banner-ads"} src={popular.image} style={{width:"100%"}}/>
                                        </a>
                                    </div>

                                ))
                            }
                        </> : <></>
                    }

                </div>
            </MediaQuery>
            <MediaQuery maxWidth={460}>
                <div className={"container-fluid p-0 popular-container"}>
                    {
                        popular.length > 0 ?  <h6 style={{fontSize:"14px", marginLeft:"0", fontWeight:"500", marginTop:"2.125rem"}}>Popular & Trending</h6> : ""
                    }


                    {
                        popular ?  <>

                            {
                                popular.slice(0,2).map((popular)=>(

                                    <div className={"popular-container-item col-md-10 col-10"} style={{marginRight:"1rem"}}>
                                        <a href={popular.url} target={"_blank"}>
                                            <img className={"banner-ads"} src={popular.image} style={{width:"100%"}}/>
                                        </a>
                                    </div>

                                ))
                            }
                        </> : <></>
                    }

                </div>
            </MediaQuery>
            <MediaQuery maxWidth={1200} minWidth={461}>
                <div className={"container-fluid p-0 popular-container"}>
                    {
                        popular.length > 0 ?  <h6 style={{fontSize:"14px", marginLeft:"0", fontWeight:"500", marginTop:"2.125rem"}}>Popular & Trending</h6> : ""
                    }


                    {
                        popular ?  <>

                            {
                                popular.slice(0,2).map((popular)=>(

                                    <div className={"popular-container-item col-md-10 col-10"} style={{marginRight:"1rem"}}>
                                        <a href={popular.url} target={"_blank"}>
                                            <img className={"banner-ads"} src={popular.image} style={{width:"100%"}}/>
                                        </a>
                                    </div>

                                ))
                            }
                        </> : <></>
                    }

                </div>
            </MediaQuery>
        </>

    )
}