import React from 'react';
import useEventbrite from 'react-eventbrite-popup-checkout';

function WebinarEventModal () {

  
  return (
    <div id="my-app">
      {/* guard for null - resolves when Eventbrite loads */}

       
        <button  id="eventbrite-widget-modal-trigger-645206057687" className={"btn"} style={{color:"white", display:"block",background:"#070707", borderRadius:"200px", padding:"0.5rem 1.75rem"}}>Register now</button>
         

    </div>
  )
}

export default WebinarEventModal
