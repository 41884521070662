import React, {Component} from "react";
import MetaTags from "react-meta-tags";
import MediaQuery from "react-responsive";
import Header from "../Landing/Header";
import searchicon from "../../../assets/Frame 39.png";
import ResourcesNav from "./ResourcesNav";
import {MdGridView, MdKeyboardArrowDown, MdOpenInNew, MdReorder} from "react-icons/md";
import SkeletonSearchResult from "../Landing/Skeletons/SkeletonSearchResult";
import SkeletonImage from "../Landing/Skeletons/SkeletonImage";
import axios from "axios";
import buildAPIUrl from "../../../Services/UrlBuilder";
import ReactPaginate from "react-paginate";
import cards from "../../../assets/Group 23.png";
import Footer from "../Landing/Footer";
import NotFound from "./NotFound";
import {EventsHandler} from "../Landing/MainFile/MainFunctions";
import SeoMetaData from "./SeoMetaData";
class Methods extends Component{
    state={
        items:[],
        loading:true,
        listview:false,
        showfilter:false,
        plantype: 0,
        filter:"",
        methods:[],
        offset:0,
        itemscount:15,
        pages:0,
        page:0,
    }
    componentDidMount() {
        if(!localStorage.getItem('token')){
            window.location.assign("/")
        }
        if (window.location.hash.includes("#")){
            this.setState({
                filter:window.location.hash.replace("#", "").replaceAll("%20", " ")
            })
        } else {
            window.scrollTo(0,0)
        }
        EventsHandler("Methods Section Opened",  document.URL)
        const response = axios.get(buildAPIUrl(`v1/ux_research_methods/`)).then((res)=>{
            console.log(res.data)
            this.setState({
                methods:res.data,
                pages:Math.floor(res.data.length / this.state.itemscount),
                loading:false
            })
        }).catch((error)=>{

        })
        //Increase views by 1
        axios.get(buildAPIUrl('v1/collections/8/')).then(()=>{

        }).catch((error)=>{

        })
    }
    handleSearch = async (e) => {
        await this.setState({
            filter:e.target.value
        })
    }
    handlePageClick =  async (event) => {
        console.log(event.selected)
       await window.scrollTo(0,0)
        let page = event.selected;
        await this.setState({
             page:page
         })

    }
    handleSearchVoc = (e) => {
        localStorage.setItem('searchquery', this.state.filter)
        window.location.assign('/all-results')
    }
    saveEvent = (id)=> {
        EventsHandler("Methods Link Clicked", document.URL, "none", id)
        //Increase views by 1
        axios.get(buildAPIUrl(`v1/ux_research_methods/${id}/`)).then(()=>{

        }).catch((error)=>{

        })

    }


    render() {
        console.log(this.state.filter.replace("%20", " "))
        const filtereditems = this.state.methods.filter((result)=> {
            if (this.state.filter === "") {
                return result
            } else if (result.research_method.toLowerCase().includes(this.state.filter.toLowerCase())) {
                return result
            }
        })


        function compareStrings(a, b) {
            // Assuming you want case-insensitive comparison
            a = a.toLowerCase();
            b = b.toLowerCase();

            return (a < b) ? -1 : (a > b) ? 1 : 0;
        }
        return (
            <>
               {/* <MetaTags>
                    <title>UX Research Methods - Research Bookmark</title>
                    <meta name="description" content="Top selected methods used in ux research" />
                    <meta property="og:title" content="UX Research Methods- Research Bookmark" />
                    <meta property="og:url" content="https://www.researchbookmark.io/research-books" />
                </MetaTags>*/}
                <SeoMetaData title={"Methods"} />
                <Header pageType={"Free"} />
                <MediaQuery minWidth={1200}>
                    <div className={"row"} style={{marginTop:"7rem"}}>
                        <div className={"col-md-2"}></div>
                        <div className={"col-md-8"}>
                            <div className="container desktop">
                                <div className="search-section col-12 col-md-12 mt-4 ">
                                    <div className="row d-flex align-items-center ">
                                        <div className=" col-12 col-md-12 d-flex justify-content-center">
                                            <form onKeyUp={this.handleSearch} style={{width:"100%"}} autoComplete="off">
                                                <div className="form-input input-group border"  style={{borderRadius:"100px", paddingLeft:"1rem", paddingRight:"1.5rem"}}>
                                                             <span className="input-group-text border-0" id="basic-addon1" style={{padding:"0"}}>
                                                <img src={searchicon}/>
                                            </span>
                                                    <input className="search-field-text1 form-control mob border-0" type="text" name="q" onChange={this.handleInput} value={this.state.q}
                                                           style={{width: "90%", paddingLeft:"1rem", borderRadius:"6px"}} placeholder="Search for methods"/>

                                                </div>
                                            </form>
                                        </div>
                                    </div>

                                </div>
                                <div className={"saved-sources"}>
                                    <div className="row">
                                        <ResourcesNav />
                                        <div className="col-md-8 col-6 d-flex align-items-center" style={{marginTop:"1rem"}}>
                                            <h1 style={{fontSize:"20px", marginLeft:"0"}}>Qualitative and Quantitative: Essential UX Research Methods and Strategies</h1>
                                        </div>
                                        <div className={"col-md-4 d-flex justify-content-end"}>

                                        </div>
                                    </div>
                                    <div className={"container-fluid border mt-5"} style={{borderRadius:"0px 0px 8px 8px", padding:"2px 37px"}}>
                                        {

                                            this.state.loading ? <>
                                                {
                                                    [1,2,3,4,5,6,7].map((n) => <SkeletonSearchResult key={n}/>)
                                                }
                                            </> : <>
                                                {
                                                    filtereditems.length > 0 ? <>
                                                        {
                                                           this.state.filter.length > 0 ? <>
                                                                   {
                                                                       filtereditems.sort(function (a,b){
                                                                           return compareStrings(a.research_method, b.research_method)
                                                                       }).map((course)=>(
                                                                           <>
                                                                               <div className={"row border-bottom"} style={{marginTop:"47px"}} >
                                                                                   <div className={"col-md-12"} id={course.id}>
                                                                                       <p style={{fontSize:"25px", fontWeight:"600"}}>
                                                                                           {course.research_method}
                                                                                       </p>
                                                                                   </div>
                                                                                   <div className={"col-md-12"}>
                                                                                       <p>
                                                                                           {course.definition}
                                                                                       </p>
                                                                                   </div>
                                                                                   <div className={"col-md-12"} style={{marginBottom:"40px"}}>
                                                                                       <a onClick={()=>this.saveEvent(course.research_method)} href={course.source} target={"_blank"}><p style={{color:"#00944D", textDecoration:"underline"}}>
                                                                                           {course.source}
                                                                                       </p></a>
                                                                                   </div>
                                                                               </div>

                                                                           </>
                                                                       ))
                                                                   }
                                                               </>
                                                        :<>
                                                                   {
                                                                       filtereditems.sort(function (a,b){
                                                                           return compareStrings(a.research_method, b.research_method)
                                                                       }).slice(this.state.page * this.state.itemscount, (this.state.page  +1) * this.state.itemscount).map((course)=>(
                                                                           <>
                                                                               <div className={"row border-bottom"} style={{marginTop:"47px"}}>
                                                                                   <div className={"col-md-12"} id={course.id}>
                                                                                       <p style={{fontSize:"25px", fontWeight:"600"}}>{course.research_method}</p>
                                                                                   </div>
                                                                                   <div className={"col-md-12"}>
                                                                                       <p>
                                                                                           {course.definition}
                                                                                       </p>
                                                                                   </div>
                                                                                   <div className={"col-md-12"} style={{marginBottom:"40px"}}>
                                                                                       <a onClick={()=>this.saveEvent(course.research_method)} href={course.source} target={"_blank"}><p style={{color:"#00944D", textDecoration:"underline"}}>
                                                                                           {course.source}
                                                                                       </p></a>
                                                                                   </div>
                                                                               </div>

                                                                           </>
                                                                       ))
                                                                   }
                                                               </>
                                                        }
                                                    </>  : <>
                                                        <div className={"container d-flex justify-content-center p-5 text-center"}>
                                                            <div>
                                                                <img src={cards}/><br/>
                                                                <h6>No results found for this keyword</h6>
                                                                <p>This word is not available in methods, try our search
                                                                    engine instead</p>
                                                                <NotFound term={this.state.filter} />

                                                            </div>
                                                        </div>
                                                    </>
                                                }

                                            </>
                                        }


                                    </div>
                                    {
                                        filtereditems.length > 0 ?
                                            <div className={"container d-flex justify-content-center"}>
                                                <ReactPaginate
                                                    pageCount={this.state.pages}
                                                    previousLabel={'Previous'}
                                                    nextLabel={'Next'}
                                                    onPageChange={this.handlePageClick}
                                                    containerClassName={'pagination'}
                                                    activeClassName={'active'}
                                                />
                                            </div>
                                            : ""
                                    }
                                </div>

                            </div>
                        </div>
                    </div>
                </MediaQuery>
                <MediaQuery maxWidth={460}>
                    <div className={"row"}>
                        <div className={"col-md-8"}>
                            <div className={"row"}>
                                <div className={"col-md-8"}>
                                    <div className="container-fluid p-0 mobile">
                                        <div className="search-section col-12 col-md-12 mb-2 mt-4">
                                            <div className="row d-flex align-items-center ">
                                                <div className=" col-12 col-md-12 d-flex justify-content-center">
                                                    <form onKeyUp={this.handleSearch} style={{width:"100%"}} autoComplete="off">
                                                        <div className="form-input form-input-group border" style={{borderRadius:"100px", paddingLeft:"1rem", paddingRight:"1.5rem"}}>
                                                            <span className="input-group-text input-group-text-notion" id="basic-addon1" style={{padding:"0"}}><img src={searchicon}/></span>
                                                            <input className="form-control search-field-text1 mob" type="text" name="q" onChange={this.handleInput} value={this.state.q}
                                                                   style={{width: "100%", border:"none"}} placeholder="Search for methods"/>

                                                        </div>
                                                    </form>
                                                </div>
                                            </div>

                                        </div>
                                        <div className={"saved-sources"}>
                                            <ResourcesNav />
                                                <div className="col-md-8 col-7 d-flex align-items-center">
                                                    <h1 style={{fontSize:"16px"}}>Qualitative and Quantitative: Essential UX Research Methods and Strategies</h1>
                                                </div>
                                            <div className={"container-fluid"} style={{borderRadius:"0px 0px 8px 8px", padding:"0 1rem"}}>
                                                {

                                                    this.state.loading ? <>
                                                        {
                                                            [1,2,3,4,5,6,7].map((n) => <SkeletonSearchResult key={n}/>)
                                                        }
                                                    </> : <>
                                                        {
                                                            filtereditems.length > 0 ? <>
                                                                {
                                                                    filtereditems.map((course)=>(
                                                                        <>
                                                                            <div className={"row border-bottom p-0"} style={{marginTop:"2.375rem"}}>
                                                                                <div className={"col-md-12"}>
                                                                                    <p style={{fontSize:"18px", fontWeight:"600"}}>{course.research_method}</p>
                                                                                </div>
                                                                                <div className={"col-md-12"}>
                                                                                    <p>
                                                                                        {course.definition}
                                                                                    </p>
                                                                                </div>
                                                                                <div className={"col-md-12"} style={{marginBottom:"0.813rem"}}>
                                                                                    <a onClick={()=>this.saveEvent(course.research_method)} href={course.source} target={"_blank"}><p style={{color:"#00944D", textDecoration:"underline", margin:"0"}}>
                                                                                        {course.source}
                                                                                    </p></a>
                                                                                </div>
                                                                            </div>

                                                                        </>
                                                                    ))
                                                                }
                                                            </>  : <>
                                                                <div className={"container d-flex justify-content-center p-5 text-center"}>
                                                                    <div>
                                                                        <img src={cards}/><br/>
                                                                        <h6>No results found for this keyword</h6>
                                                                        <p>This word is not available in methods, try our search
                                                                            engine instead</p>
                                                                        <NotFound term={this.state.filter} />

                                                                    </div>
                                                                </div>
                                                            </>
                                                        }

                                                    </>
                                                }


                                            </div>
                                            {
                                                filtereditems.length > 0 ?
                                                    <div className={"container-fluid p-0 d-flex justify-content-center"}>
                                                        <ReactPaginate
                                                            pageCount={this.state.pages}
                                                            previousLabel={'Previous'}
                                                            nextLabel={'Next'}
                                                            onPageChange={this.handlePageClick}
                                                            containerClassName={'pagination'}
                                                            activeClassName={'active'}
                                                        />
                                                    </div>
                                                    : ""
                                            }
                                        </div>

                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </MediaQuery>
                <MediaQuery maxWidth={1200} minWidth={461}>
                    <div className={"row"}>
                        <div className={"col-md-2"}></div>
                        <div className={"col-md-8"}>
                            <div className="container desktop">
                                <div className="search-section col-12 col-md-12 mt-5 mb-2 ">
                                    <div className="row d-flex align-items-center ">
                                        <div className=" col-12 col-md-12 d-flex justify-content-center">
                                            <form onKeyUp={this.handleSearch} style={{width:"100%"}} autoComplete="off">
                                                <div className="form-input input-group border" style={{borderRadius:"100px", paddingLeft:"1rem", paddingRight:"1.5rem"}}>
                                                             <span className="input-group-text border-0" id="basic-addon1" style={{padding:"0"}}>
                                                <img src={searchicon}/>
                                            </span>
                                                    <input className="search-field-text1 form-control mob border-0" type="text" name="q" onChange={this.handleInput} value={this.state.q}
                                                           style={{width: "90%", paddingLeft:"1rem", borderRadius:"6px"}} placeholder="Search for methods"/>

                                                </div>
                                            </form>
                                        </div>
                                    </div>

                                </div>
                                <div className="row">
                                    <ResourcesNav />
                                    <div className="col-md-8 col-6 d-flex align-items-center" style={{marginTop:"30px"}}>
                                        <h1 style={{fontSize:"20px"}}>Qualitative and Quantitative: Essential UX Research Methods and Strategies</h1>
                                    </div>
                                    <div className={"col-md-4 d-flex justify-content-end"}>

                                    </div>
                                </div>
                                <div className={"container-fluid border mt-3"} style={{borderRadius:"0px 0px 8px 8px", padding:"2px 37px"}}>
                                    {

                                        this.state.loading ? <>
                                            {
                                                [1,2,3,4,5,6,7].map((n) => <SkeletonSearchResult key={n}/>)
                                            }
                                        </> : <>
                                            {
                                                filtereditems.length > 0 ? <>
                                                    {
                                                        filtereditems.map((course)=>(
                                                            <>
                                                                <div className={"row border-bottom"} style={{marginTop:"47px"}}>
                                                                    <div className={"col-md-12"}>
                                                                        <p style={{fontSize:"25px", fontWeight:"600"}}>{course.research_method}</p>
                                                                    </div>
                                                                    <div className={"col-md-12"}>
                                                                        <p>
                                                                            {course.definition}
                                                                        </p>
                                                                    </div>
                                                                    <div className={"col-md-12"} style={{marginBottom:"40px"}}>
                                                                        <a onClick={()=>this.saveEvent(course.research_method)} href={course.source} target={"_blank"}><p style={{color:"#00944D", textDecoration:"underline"}}>
                                                                            {course.source}
                                                                        </p></a>
                                                                    </div>
                                                                </div>

                                                            </>
                                                        ))
                                                    }
                                                </>  : <>
                                                    <div className={"container d-flex justify-content-center p-5 text-center"}>
                                                        <div>
                                                            <img src={cards}/><br/>
                                                            <h6>No results found for this keyword</h6>
                                                            <p>This word is not available in methods, try our search
                                                                engine instead</p>
                                                            <NotFound term={this.state.filter} />

                                                        </div>
                                                    </div>
                                                </>
                                            }

                                        </>
                                    }


                                </div>
                                {
                                    filtereditems.length > 0 ?
                                        <div className={"container d-flex justify-content-center"}>
                                            <ReactPaginate
                                                pageCount={this.state.pages}
                                                previousLabel={'Previous'}
                                                nextLabel={'Next'}
                                                onPageChange={this.handlePageClick}
                                                containerClassName={'pagination'}
                                                activeClassName={'active'}
                                            />
                                        </div>
                                        : ""
                                }
                            </div>
                        </div>
                    </div>
                </MediaQuery>
                <Footer />
            </>
        );
    }
}
export default Methods