import React, {Component} from "react";
import {getSuggestions, showOnBoardingModal} from "./MainFunctions";
import axios from "axios";
import buildAPIUrl from "../../../../Services/UrlBuilder";
import {Link} from "react-router-dom";
import MediaQuery from "react-responsive";
import { MdOutlineAccessTime, MdTrendingUp} from "react-icons/md";
import searchicon from "../../../../assets/Frame 39.png"
import blue from "../../../../assets/Templates.svg";
import peach from "../../../../assets/tools.svg";
import green from "../../../../assets/communities.svg";
import {FaSearch} from "react-icons/fa";

class SearchArea extends Component{
    constructor(props) {
        super(props);

        this.wrapperRef = React.createRef();
        this.handleClickOutside = this.handleClickOutside.bind(this);
    }
    state = {
        image: localStorage.getItem('image'),
        clientID: process.env.REACT_APP_GOOGLE_CLIENT_ID,
        token: localStorage.getItem('token'),
        q: "",
        active: false,
        name:localStorage.getItem('username'),
        suggestion:[],
        tags:[],
        stepsEnabled:true,
        initialStep:0,
        isTourOpen: false,
        modalshow:false,
        prompt:true,
        item1:"",
        item2:"",
        item3:"",
        showsuggest:false,
        userHistory:[],
        activeinput:false,
        item4:[],
        item5:[],
        item6:[],
        rbresults:[],
        communitysuggestions:[],
        methodssuggestions:[],
        bookssuggestions:[],
        coursessuggestions:[],
        vocabularysuggestions:[],
        wrapperRef:React.createRef()
    }
    handleClickOutside(event) {
        if (!this.wrapperRef.current.contains(event.target)) {
            this.setState({
                activeinput:false,
                q:"",
                suggestion:[],
                communitysuggestions:[],
                methodssuggestions:[],
                bookssuggestions:[],
                coursessuggestions:[],
                vocabularysuggestions:[],
            })

        }
    }
    componentDidMount() {
        document.addEventListener("mousedown", this.handleClickOutside);
        //Check if user is a first time visitor
        const visit = showOnBoardingModal()
        this.setState({
            modalshow:visit
        })
        if(localStorage.getItem('prompt')==="0"){
            this.setState({
                prompt:false
            })
        }
        axios.get(buildAPIUrl('v1/save_search_queries/suggest')).then((res)=>{
            console.log(res.data[21])
            this.setState({
                tags:res.data,
                loading:false,
                item1:res.data[0],
                item2:res.data[1],
                item3:res.data[2],
            })

        }).catch((error)=>{

        })
        //Get users history and display it when they start typing in the searchbox
        if (localStorage.getItem('token')){
            axios.get(buildAPIUrl('v1/save_search_queries/'), {headers: {Authorization: `Bearer ${localStorage.getItem('token')}`}}).then((res) => {
                this.setState({
                    userHistory: res.data,
                })

            })
        }

        //Get resources for the search area
        axios.get(buildAPIUrl('v1/collections/?is_top_collection=1')).then((res)=>{
            this.setState({
                item4:res.data[0],
                item5:res.data[1],
                item6:res.data[2],
            })
        })
    }

    handleInput = async (e) => {

        //Get search suggestions as the user types
        this.setState({
            activeinput:true
        })


        if(e.target.value.length > 0){
            await this.setState({
                [e.target.name]: e.target.value
            })
            //Search from RB collections
           await axios.post(buildAPIUrl('v1/save_search_queries/search/'), {
                search_query:e.target.value
            }).then((res)=>{

                this.setState({
                    rbresults:res.data,
                    communitysuggestions:res.data[0].ResearchCommunity,
                    methodssuggestions:res.data[3].UXResearchMethod,
                    bookssuggestions:res.data[1].UXBook,
                    coursessuggestions:res.data[2].UXResearchCourses,
                })
            }).catch((error)=>{
            })
         getSuggestions(e,this.state.token,   async (res)=>{
                await this.setState({
                    suggestion:res.data.suggestionGroups[0].searchSuggestions,
                    showsuggest:true
                })
            }, (error)=>{
            })
        } else {
            await this.setState({
                showsuggest:false,
                q:""
            })
        }



    }
    //Detect when a user leaves the input field
    handleLeaveInput = () =>{
       setTimeout(()=>{
           this.setState({
               activeinput:false
           })
       }, 50000)
    }
    handleSearch = async (e) =>{
        e.preventDefault();
       await this.handleSuggestion(this.state.q);
    }
    openLink1 = (query) =>{
        localStorage.setItem('searchquery', query)
        window.location.assign(`/all-results`)
    }
    closetour = () => {
        localStorage.setItem('firstvisit', '0')
        this.setState({
            isTourOpen:false
        })
    }

    handleSuggestion = async (item)=>{
        this.setState({
            q:item,
            suggestion:[]
        })
        if(localStorage.getItem('token')){
            const res = await axios.post(buildAPIUrl(`v1/save_search_queries/`), {
                query_search: item,
            }, {
                headers:{
                    'Authorization' : `Bearer ${localStorage.getItem('token')}`
                }
            }).then((res) => {
                localStorage.setItem('searchquery', this.state.q)
                window.location.assign(`/all-results?q=${this.state.q}`)
            });
        } else {
            const res = await axios.post(buildAPIUrl(`v1/save_search_queries/`), {
                query_search: item,
            }
            ).then((res) => {
                localStorage.setItem('searchquery', this.state.q)
                window.location.assign(`/all-results?q=${this.state.q}`)
            });
        }

    }
    handleChromeExtension = (index) => {
        if(index===1){
            //create a prompt localstorage variable.
            //if it exists, do not show the prompt
            localStorage.setItem('prompt', "0")
            this.setState({
                prompt:false
            })
            //direct user to chrome extension download page
          /*  window.open('https://chrome.google.com/webstore/detail/rb-chrome-extension/lopemdiebdhmfpofjommgpfoiiocgbin')*/
            window.open('https://researchbookmark.io/webinar')

        } else {
            //hide the prompt
            //create a prompt localstorage variable.
            //if it exists, do not show the prompt
            localStorage.setItem('prompt', "0")
            this.setState({
                prompt:false
            })
        }
    }
    handleRbSuggestion = (url) =>{
        window.location.assign(url)
    }

    render() {
        return(
            <>
                <MediaQuery minWidth={1200}>
                    <div className="search-section1 container-fluid">
                        <div className="welcome-message container">
                            <>
                                <div className="col-md-8" style={{marginTop:"8.875rem"}}>
                                    <h1><span style={{color:"#522FFF"}}>User experience</span> articles  , use cases, podcast, books and more... </h1>
                                </div>
                            </>

                        </div>
                       <div className="row d-flex justify-content-center">
                           <div className="col-md-8" style={{marginTop:"2.125rem", padding:" .688rem 2rem"}} >
                               <form onSubmit={this.handleSearch} autoComplete="off">
                                   <div ref={this.wrapperRef} className="container">
                                       <div className={"row"} style={{zIndex:"1000", position:"relative"}}>
                                           <div className={"col-md-12 col-10 p-0 m-0 position-relative d-flex align-items-center"}>
                                               <input  className="search-field-text" type="text" name="q" onChange={this.handleInput} onClick={this.handleInput} value={this.state.q} onBlur={this.handleLeaveInput}
                                                       placeholder="Search unlimited resources" style={this.state.suggestion.length || this.state.activeinput > 0 ? {borderRadius:"2rem 2rem 0 0"} : {borderRadius:"28px"}}/>
                                               <FaSearch className={"position-absolute"} style={{right:"20px", color:"#868590"}} onTouchStart={this.handleSearch} onClick={this.handleSearch} />
                                           </div>
                                           <div className={"col-md-12 col-10 p-0 m-0 position-relative"}>
                                               <div className={"position-absolute col-12 col-md-12"}>

                                                   <div className="form-input1 col-md-12" style={{marginTop:"-.2rem", background:"white", position:"relative"}}>
                                                       {
                                                           this.state.q  && this.state.communitysuggestions && this.state.communitysuggestions.slice(0,1).map((suggest, i)=>(
                                                               <div className=" rb" style={this.state.showsuggest ? {cursor:"pointer"}: {cursor:"pointer", display:"none"}} key={i} onClick={()=>this.handleRbSuggestion(`/uxr-communities#${suggest.name}`)}> {suggest.name} <span>| In Communities</span></div>
                                                           ))
                                                       }
                                                       {
                                                           this.state.q  && this.state.methodssuggestions && this.state.methodssuggestions.slice(0,1).map((suggest, i)=>(
                                                               <div className=" rb" style={this.state.showsuggest ? {cursor:"pointer"}: {cursor:"pointer", display:"none"}} key={i} onClick={()=>this.handleRbSuggestion(`/uxr-methods#${suggest.research_method}`)}> {suggest.research_method} <span>| In Research Methods</span></div>
                                                           ))
                                                       }
                                                       {
                                                           this.state.q  && this.state.bookssuggestions && this.state.bookssuggestions.slice(0,1).map((suggest, i)=>(
                                                               <div className=" rb" style={this.state.showsuggest ? {cursor:"pointer"}: {cursor:"pointer", display:"none"}} key={i} onClick={()=>this.handleRbSuggestion(`/research-books#${suggest.name}`)}> {suggest.name} <span>| In Research Books</span> </div>
                                                           ))
                                                       }
                                                       {
                                                           this.state.q  && this.state.coursessuggestions && this.state.coursessuggestions.slice(0,1).map((suggest, i)=>(
                                                               <div className="rb" style={this.state.showsuggest ? {cursor:"pointer"}: {cursor:"pointer", display:"none"}} key={i} onClick={()=>this.handleRbSuggestion(`/uxr-courses#${suggest.name}`)}> {suggest.name} <span>| In Research Courses</span> </div>
                                                           ))
                                                       }
                                                       {
                                                           this.state.activeinput ?  <div className={"container pt-3 pb-3 border-top"} style={{}}>
                                                               <h6 style={{color:"#767676", fontSize:"12px", lineHeight:"15px", fontWeight:"400", marginTop:"2.5rem"}}>Your recent searches</h6>
                                                               {
                                                                   this.state.userHistory && this.state.userHistory.sort(function (a,b){return b.id-a.id}).slice(0,5).map((histo)=>(
                                                                       <div onClick={()=>this.handleSuggestion(histo.query_search)}  style={{background:"#F1F1F1", color:"#00944D", fontSize:"14px", margin:".5rem 1rem .5rem 0", borderRadius:"100px", padding:"5px 11px", cursor:"pointer", width:"fit-content", display:"inline-block"}}>{histo.query_search} <span style={{color:"#AFAFAF"}}><MdOutlineAccessTime /></span> </div>
                                                                   ))
                                                               }
                                                           </div> : ""
                                                       }
                                                       {
                                                           this.state.activeinput &&  !this.state.q.length > 0 ? <>
                                                               <div className={"container-fluid p-0 border-top"}>
                                                                   <div className={"row mt-3 pb-5"} style={{padding:"0 1.5rem"}}>

                                                                       <div className="col-md-4 " >
                                                                           <div className={"container-fluid searchb"} style={{padding:"1.375rem 1.5rem 1.125rem 1.25rem",  borderRadius:"10px"}}>
                                                                               <a href={this.state.item6.url}>
                                                                                   <div className="categories-item" style={{ borderRadius:"10px", marginTop:"0.688rem"}}>
                                                                                       <div className="categories-content" >
                                                                                           <img src={green}/>
                                                                                           <h6 style={{textAlign:"left", marginTop:"1.063rem"}}>
                                                                                               {this.state.item6.header}
                                                                                           </h6>
                                                                                           <p>
                                                                                               {this.state.item6.description}
                                                                                           </p>
                                                                                       </div>
                                                                                   </div>
                                                                               </a>
                                                                           </div>

                                                                       </div>
                                                                       <div className="col-md-4" >
                                                                           <div className={"container-fluid searchb"} style={{padding:"1.375rem 1.5rem 1.125rem 1.25rem",  borderRadius:"10px"}}>
                                                                               <a href={this.state.item4.url}>
                                                                                   <div className="categories-item" style={{ borderRadius:"10px"}}>
                                                                                       <div className="categories-content" >
                                                                                           <img src={blue}/>
                                                                                           <h6 style={{textAlign:"left", marginTop:"0.688rem"}}>
                                                                                               {this.state.item4.header}
                                                                                           </h6>
                                                                                           <p>
                                                                                               {this.state.item4.description}
                                                                                           </p>

                                                                                       </div>
                                                                                   </div>
                                                                               </a>
                                                                           </div>


                                                                       </div>

                                                                       <div className="col-md-4">
                                                                           <div className={"container-fluid searchb"} style={{padding:"1.375rem 1.5rem 1.125rem 1.25rem",  borderRadius:"10px"}}>
                                                                               <a href={this.state.item5.url}>
                                                                                   <div className="categories-item" style={{borderRadius:"10px"}}>
                                                                                       <div className="categories-content" >
                                                                                           <img src={peach} style={{width:"1.25rem", height:"1.25rem", display:"inline-block"}}/>
                                                                                           <h6 style={{textAlign:"left", marginTop:"0.688rem",}}>
                                                                                               {this.state.item5.header}
                                                                                           </h6>
                                                                                           <p>
                                                                                               {this.state.item5.description}
                                                                                           </p>
                                                                                       </div>
                                                                                   </div>
                                                                               </a>
                                                                           </div>

                                                                       </div>

                                                                   </div>
                                                               </div>
                                                           </> : <>

                                                           </>
                                                       }


                                                       {
                                                           this.state.q  && this.state.suggestion.map((suggest, i)=>(
                                                               <div className="suggest-option" style={this.state.showsuggest ? {cursor:"pointer"}: {cursor:"pointer", display:"none"}} key={i} onClick={()=>this.handleSuggestion(suggest.displayText)}> {suggest.displayText}</div>
                                                           ))
                                                       }
                                                   </div>
                                               </div>

                                           </div>
                                       </div>
                                   </div>

                               </form>
                           </div>
                       </div>

                    </div>
                </MediaQuery>
                <MediaQuery minWidth={461} maxWidth={1200}>
                   <div className={"tablet"}>
                       <div className="search-section1 container-fluid">
                           <div className="welcome-message container">
                               <>
                                   <div className="col-md-10" style={{marginTop:"2.875rem"}}>
                                       <h1><span style={{color:"#522FFF"}}>User experience</span> articles  , use cases, podcast, books and more... </h1>
                                   </div>
                               </>

                           </div>
                           <div className="row d-flex justify-content-center">
                               <div className="col-md-10" style={{marginTop:"2.125rem", padding:" .688rem 2rem"}} >
                                   <form onSubmit={this.handleSearch} autoComplete="off">
                                       <div ref={this.wrapperRef} className="container">
                                           <div className={"row"} style={{zIndex:"1000", position:"relative"}}>
                                               <div className={"col-md-12 col-10 p-0 m-0 position-relative d-flex align-items-center"}>
                                                   <input  className="search-field-text" type="text" name="q" onChange={this.handleInput} onClick={this.handleInput} value={this.state.q} onBlur={this.handleLeaveInput}
                                                           placeholder="Search unlimited resources" style={this.state.suggestion.length || this.state.activeinput > 0 ? {borderRadius:"2rem 2rem 0 0"} : {borderRadius:"28px"}}/>
                                                   <FaSearch className={"position-absolute"} style={{right:"20px", color:"#868590"}} onTouchStart={this.handleSearch} onClick={this.handleSearch} />
                                               </div>
                                               <div className={"col-md-12 col-10 p-0 m-0 position-relative"}>
                                                   <div className={"position-absolute col-12 col-md-12"}>

                                                       <div className="form-input1 col-md-12" style={{marginTop:"-.2rem", background:"white", position:"relative"}}>
                                                           {
                                                               this.state.q  && this.state.communitysuggestions && this.state.communitysuggestions.slice(0,1).map((suggest, i)=>(
                                                                   <div className=" rb" style={this.state.showsuggest ? {cursor:"pointer"}: {cursor:"pointer", display:"none"}} key={i} onClick={()=>this.handleRbSuggestion(`/uxr-communities#${suggest.name}`)}> {suggest.name} <span>| In Communities</span></div>
                                                               ))
                                                           }
                                                           {
                                                               this.state.q  && this.state.methodssuggestions && this.state.methodssuggestions.slice(0,1).map((suggest, i)=>(
                                                                   <div className=" rb" style={this.state.showsuggest ? {cursor:"pointer"}: {cursor:"pointer", display:"none"}} key={i} onClick={()=>this.handleRbSuggestion(`/uxr-methods#${suggest.research_method}`)}> {suggest.research_method} <span>| In Research Methods</span></div>
                                                               ))
                                                           }
                                                           {
                                                               this.state.q  && this.state.bookssuggestions && this.state.bookssuggestions.slice(0,1).map((suggest, i)=>(
                                                                   <div className=" rb" style={this.state.showsuggest ? {cursor:"pointer"}: {cursor:"pointer", display:"none"}} key={i} onClick={()=>this.handleRbSuggestion(`/research-books#${suggest.name}`)}> {suggest.name} <span>| In Research Books</span> </div>
                                                               ))
                                                           }
                                                           {
                                                               this.state.q  && this.state.coursessuggestions && this.state.coursessuggestions.slice(0,1).map((suggest, i)=>(
                                                                   <div className="rb" style={this.state.showsuggest ? {cursor:"pointer"}: {cursor:"pointer", display:"none"}} key={i} onClick={()=>this.handleRbSuggestion(`/uxr-courses#${suggest.name}`)}> {suggest.name} <span>| In Research Courses</span> </div>
                                                               ))
                                                           }
                                                           {
                                                               this.state.activeinput ?  <div className={"container pt-3 pb-3 border-top"} style={{}}>
                                                                   <h6 style={{color:"#767676", fontSize:"12px", lineHeight:"15px", fontWeight:"400", marginTop:"2.5rem"}}>Your recent searches</h6>
                                                                   {
                                                                       this.state.userHistory && this.state.userHistory.sort(function (a,b){return b.id-a.id}).slice(0,5).map((histo)=>(
                                                                           <div onClick={()=>this.handleSuggestion(histo.query_search)}  style={{background:"#F1F1F1", color:"#00944D", fontSize:"14px", margin:".5rem 1rem .5rem 0", borderRadius:"100px", padding:"5px 11px", cursor:"pointer", width:"fit-content", display:"inline-block"}}>{histo.query_search} <span style={{color:"#AFAFAF"}}><MdOutlineAccessTime /></span> </div>
                                                                       ))
                                                                   }
                                                               </div> : ""
                                                           }
                                                           {
                                                               this.state.activeinput &&  !this.state.q.length > 0 ? <>
                                                                   <div className={"container-fluid p-0 border-top"}>
                                                                       <div className={"row mt-3 pb-5"} style={{padding:"0 1.5rem"}}>

                                                                           <div className="col-md-4 " >
                                                                               <div className={"container-fluid searchb"} style={{padding:"1.375rem 1.5rem 1.125rem 1.25rem",  borderRadius:"10px"}}>
                                                                                   <a href={this.state.item6.url}>
                                                                                       <div className="categories-item" style={{ borderRadius:"10px", marginTop:"0.688rem"}}>
                                                                                           <div className="categories-content" >
                                                                                               <img src={green}/>
                                                                                               <h6 style={{textAlign:"left", marginTop:"1.063rem"}}>
                                                                                                   {this.state.item6.header}
                                                                                               </h6>
                                                                                               <p>
                                                                                                   {this.state.item6.description}
                                                                                               </p>
                                                                                           </div>
                                                                                       </div>
                                                                                   </a>
                                                                               </div>

                                                                           </div>
                                                                           <div className="col-md-4" >
                                                                               <div className={"container-fluid searchb"} style={{padding:"1.375rem 1.5rem 1.125rem 1.25rem",  borderRadius:"10px"}}>
                                                                                   <a href={this.state.item4.url}>
                                                                                       <div className="categories-item" style={{ borderRadius:"10px"}}>
                                                                                           <div className="categories-content" >
                                                                                               <img src={blue}/>
                                                                                               <h6 style={{textAlign:"left", marginTop:"0.688rem"}}>
                                                                                                   {this.state.item4.header}
                                                                                               </h6>
                                                                                               <p>
                                                                                                   {this.state.item4.description}
                                                                                               </p>

                                                                                           </div>
                                                                                       </div>
                                                                                   </a>
                                                                               </div>


                                                                           </div>

                                                                           <div className="col-md-4">
                                                                               <div className={"container-fluid searchb"} style={{padding:"1.375rem 1.5rem 1.125rem 1.25rem",  borderRadius:"10px"}}>
                                                                                   <a href={this.state.item5.url}>
                                                                                       <div className="categories-item" style={{borderRadius:"10px"}}>
                                                                                           <div className="categories-content" >
                                                                                               <img src={peach} style={{width:"1.25rem", height:"1.25rem", display:"inline-block"}}/>
                                                                                               <h6 style={{textAlign:"left", marginTop:"0.688rem",}}>
                                                                                                   {this.state.item5.header}
                                                                                               </h6>
                                                                                               <p>
                                                                                                   {this.state.item5.description}
                                                                                               </p>
                                                                                           </div>
                                                                                       </div>
                                                                                   </a>
                                                                               </div>

                                                                           </div>

                                                                       </div>
                                                                   </div>
                                                               </> : <>

                                                               </>
                                                           }


                                                           {
                                                               this.state.q  && this.state.suggestion.map((suggest, i)=>(
                                                                   <div className="suggest-option" style={this.state.showsuggest ? {cursor:"pointer"}: {cursor:"pointer", display:"none"}} key={i} onClick={()=>this.handleSuggestion(suggest.displayText)}> {suggest.displayText}</div>
                                                               ))
                                                           }
                                                       </div>
                                                   </div>

                                               </div>
                                           </div>
                                       </div>

                                   </form>
                               </div>
                           </div>

                       </div>
                   </div>
                </MediaQuery>
                <MediaQuery maxWidth={460}>
                    <div className={"mobile"}>
                        <div className="search-section1 container-fluid">
                            <div className="welcome-message container">
                                <>
                                    <div className="col-md-8 px-2" >
                                        <h1><span style={{color:"#522FFF"}}>User experience</span> articles, use cases, podcast, books and more... </h1>
                                    </div>
                                </>

                            </div>
                            <div className="row d-flex justify-content-center">
                                <div className="col-md-8" style={{marginTop:"2.125rem"}} >
                                    <form onSubmit={this.handleSearch} autoComplete="off">
                                        <div ref={this.wrapperRef} className="container">
                                            <div className={"row d-flex justify-content-center"} style={{zIndex:"1000", position:"relative"}}>
                                                <div className={"col-md-12 col-12 p-0 m-0 position-relative d-flex align-items-center"}>
                                                    <input  className="search-field-text" type="text" name="q" onChange={this.handleInput} onClick={this.handleInput} value={this.state.q} onBlur={this.handleLeaveInput}
                                                            placeholder="Search unlimited resources" style={this.state.suggestion.length || this.state.activeinput > 0 ? {borderRadius:"28px 28px 0 0"} : {borderRadius:"28px"}}/>
                                                    <FaSearch className={"position-absolute"} style={{right:"20px", color:"#868590"}} onTouchStart={this.handleSearch} onClick={this.handleSearch} />
                                                </div>
                                                <div className={"col-md-10 col-12 p-0 m-0 position-relative"}>
                                                    <div className={"position-absolute col-12 col-md-12"}>

                                                        <div className="form-input1 col-md-12" style={{marginTop:"-.2rem", background:"white", position:"relative"}}>
                                                            {
                                                                this.state.q  && this.state.communitysuggestions && this.state.communitysuggestions.slice(0,1).map((suggest, i)=>(
                                                                    <div className=" rb" style={this.state.showsuggest ? {cursor:"pointer"}: {cursor:"pointer", display:"none"}} key={i} onClick={()=>this.handleRbSuggestion(`/uxr-communities#${suggest.name}`)}> {suggest.name} <span>| In Communities</span></div>
                                                                ))
                                                            }
                                                            {
                                                                this.state.q  && this.state.methodssuggestions && this.state.methodssuggestions.slice(0,1).map((suggest, i)=>(
                                                                    <div className=" rb" style={this.state.showsuggest ? {cursor:"pointer"}: {cursor:"pointer", display:"none"}} key={i} onClick={()=>this.handleRbSuggestion(`/uxr-methods#${suggest.research_method}`)}> {suggest.research_method} <span>| In Research Methods</span></div>
                                                                ))
                                                            }
                                                            {
                                                                this.state.q  && this.state.bookssuggestions && this.state.bookssuggestions.slice(0,1).map((suggest, i)=>(
                                                                    <div className=" rb" style={this.state.showsuggest ? {cursor:"pointer"}: {cursor:"pointer", display:"none"}} key={i} onClick={()=>this.handleRbSuggestion(`/research-books#${suggest.name}`)}> {suggest.name} <span>| In Research Books</span> </div>
                                                                ))
                                                            }
                                                            {
                                                                this.state.q  && this.state.coursessuggestions && this.state.coursessuggestions.slice(0,1).map((suggest, i)=>(
                                                                    <div className="rb" style={this.state.showsuggest ? {cursor:"pointer"}: {cursor:"pointer", display:"none"}} key={i} onClick={()=>this.handleRbSuggestion(`/uxr-courses#${suggest.name}`)}> {suggest.name} <span>| In Research Courses</span> </div>
                                                                ))
                                                            }
                                                            {
                                                                this.state.activeinput ?  <div className={"container pt-3 pb-3 border-top"} style={{}}>
                                                                    <h6 style={{color:"#767676", fontSize:"12px", lineHeight:"15px", fontWeight:"400", marginTop:"2.5rem"}}>Your recent searches</h6>
                                                                    {
                                                                        this.state.userHistory && this.state.userHistory.sort(function (a,b){return b.id-a.id}).slice(0,5).map((histo)=>(
                                                                            <div onClick={()=>this.handleSuggestion(histo.query_search)}  style={{background:"#F1F1F1", color:"#00944D", fontSize:"14px", margin:".5rem .5rem .5rem 0", borderRadius:"100px", padding:"5px 11px", cursor:"pointer", display:"inline-block", width:"fit-content"}}>{histo.query_search} <span style={{color:"#AFAFAF"}}><MdOutlineAccessTime /></span> </div>
                                                                        ))
                                                                    }
                                                                </div> : ""
                                                            }
                                                            {
                                                                this.state.activeinput &&  !this.state.q.length > 0 ? <>
                                                                    <div className={"container-fluid p-0 border-top"}>
                                                                        <div className={"row mt-3 pb-5"} style={{padding:"0 1.5rem"}}>

                                                                            <div className="col-md-4 " >
                                                                                <div className={"container-fluid searchb"} style={{padding:"1.375rem 1.5rem 1.125rem 1.25rem",  borderRadius:"10px"}}>
                                                                                    <a href={this.state.item6.url}>
                                                                                        <div className="categories-item" style={{ borderRadius:"10px", marginTop:"0.688rem"}}>
                                                                                            <div className="categories-content" >
                                                                                                <img src={green}/>
                                                                                                <h6 style={{textAlign:"left", marginTop:"1.063rem"}}>
                                                                                                    {this.state.item6.header}
                                                                                                </h6>
                                                                                                <p>
                                                                                                    {this.state.item6.description}
                                                                                                </p>
                                                                                            </div>
                                                                                        </div>
                                                                                    </a>
                                                                                </div>

                                                                            </div>
                                                                            <div className="col-md-4 mt-3" >
                                                                                <div className={"container-fluid searchb"} style={{padding:"1.375rem 1.5rem 1.125rem 1.25rem",  borderRadius:"10px"}}>
                                                                                    <a href={this.state.item4.url}>
                                                                                        <div className="categories-item" style={{ borderRadius:"10px"}}>
                                                                                            <div className="categories-content" >
                                                                                                <img src={blue}/>
                                                                                                <h6 style={{textAlign:"left", marginTop:"0.688rem"}}>
                                                                                                    {this.state.item4.header}
                                                                                                </h6>
                                                                                                <p>
                                                                                                    {this.state.item4.description}
                                                                                                </p>

                                                                                            </div>
                                                                                        </div>
                                                                                    </a>
                                                                                </div>


                                                                            </div>

                                                                            <div className="col-md-4 mt-3">
                                                                                <div className={"container-fluid searchb"} style={{padding:"1.375rem 1.5rem 1.125rem 1.25rem",  borderRadius:"10px"}}>
                                                                                    <a href={this.state.item5.url}>
                                                                                        <div className="categories-item" style={{borderRadius:"10px"}}>
                                                                                            <div className="categories-content" >
                                                                                                <img src={peach} style={{width:"1.25rem", height:"1.25rem", display:"inline-block"}}/>
                                                                                                <h6 style={{textAlign:"left", marginTop:"0.688rem",}}>
                                                                                                    {this.state.item5.header}
                                                                                                </h6>
                                                                                                <p>
                                                                                                    {this.state.item5.description}
                                                                                                </p>
                                                                                            </div>
                                                                                        </div>
                                                                                    </a>
                                                                                </div>

                                                                            </div>

                                                                        </div>
                                                                    </div>
                                                                </> : <>

                                                                </>
                                                            }


                                                            {
                                                                this.state.q  && this.state.suggestion.map((suggest, i)=>(
                                                                    <div className="suggest-option" style={this.state.showsuggest ? {cursor:"pointer"}: {cursor:"pointer", display:"none"}} key={i} onClick={()=>this.handleSuggestion(suggest.displayText)}> {suggest.displayText}</div>
                                                                ))
                                                            }
                                                        </div>
                                                    </div>

                                                </div>
                                            </div>
                                        </div>

                                    </form>
                                </div>
                            </div>

                        </div>
                    </div>

                </MediaQuery>

            </>
        )
    }
}
export default SearchArea